export const toDateTime = (
  date: string | Date,
  locales: string[] | readonly string[]
) =>
  new Intl.DateTimeFormat([...locales], {
    dateStyle: 'medium',
    timeStyle: 'short',
  }).format(new Date(date));

export const toDateOnly = (
  date: string | Date,
  locales: string[] | readonly string[]
) =>
  new Intl.DateTimeFormat([...locales], {
    dateStyle: 'medium',
  }).format(new Date(date));
