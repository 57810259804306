import { useQuery } from '@tanstack/react-query';
import { useNotification } from './useNotification';
import { useFetchApi } from './useFetchApi';
import { BusinessConnection } from '../shared/types';
import { useCallback } from 'react';

export const useBuyers = () => {
  const fetch = useFetchApi();
  const { apiError } = useNotification();

  const { data, isLoading } = useQuery({
    queryKey: ['buyers'],
    queryFn: () =>
      fetch('business-connections/buyers') as Promise<BusinessConnection[]>,
    onError: apiError,
  });

  const findBuyerById = useCallback(
    (buyerId: string) => {
      if (!buyerId) return;
      return data?.find(
        (connection: BusinessConnection) =>
          connection?.sourceBusiness.id === buyerId
      )?.sourceBusiness;
    },
    [data]
  );

  return {
    data,
    isLoading,
    findBuyerById,
  };
};
