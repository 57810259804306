import { PropsWithChildren } from 'react';
import {
  Stack,
  Flex,
  Divider,
  ActionIcon,
  Icon,
} from '@liveeo/component-library';
import classes from './EmptyTable.module.css';

type Props = {
  handleClose: () => void;
};

export const EmptyTable = ({ children }: PropsWithChildren) => (
  <Stack>
    <Flex className={classes.root} direction="column" align="center" gap="md">
      {children}
    </Flex>
  </Stack>
);

const Footer = ({ handleClose }: Props) => (
  <div className={classes.footer}>
    <Divider />
    <Flex direction="row-reverse" p="xs">
      <ActionIcon
        onClick={handleClose}
        bg="transparent"
        data-testid="closeTable"
      >
        <Icon icon="chevron-left" color="#FFF" />
      </ActionIcon>
      <Divider orientation="vertical" mr={10} />
    </Flex>
  </div>
);

EmptyTable.Footer = Footer;
