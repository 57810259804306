import { Source, Layer, useMap } from 'react-map-gl';
import type { LineLayer } from 'react-map-gl';
import type { FeatureCollection } from 'geojson';
import { usePlots, useLayerState, useHoveredPlotId } from '../../hooks';
import { Expression, FillLayout } from 'mapbox-gl';
import { memo } from 'react';
import { useTableState } from '../../hooks/useTableState';

const plotOutlineStyle: LineLayer = {
  id: 'plot-outline',
  type: 'line',
  source: 'plots',
  paint: {
    'line-color': '#FCFCFC',
    'line-width': 4,
    'line-opacity': 1,
  },
};

const riskOfDeforestationColor: Expression = [
  'case',
  ['==', ['get', 'riskOfDeforestation'], 'high'],
  '#D4111B',
  ['==', ['get', 'riskOfDeforestation'], 'low'],
  '#512BB5',
  '#EBFF00',
];

const PlotsLayer = () => {
  const { isLoading, plotsAsFeatureCollection } = usePlots();
  const { layerState } = useLayerState();
  const { tableState } = useTableState();
  const { hoveredPlotId } = useHoveredPlotId();
  const { deforestationMap: map } = useMap();
  const selectedPlotId = tableState.sel || '';
  const layerVisibility: FillLayout = {
    visibility: layerState.plots ? 'visible' : 'none',
  };

  return !isLoading && map?.getMap() ? (
    <Source
      id="plots"
      type="geojson"
      data={plotsAsFeatureCollection as FeatureCollection}
    >
      <Layer
        id="plot-fill"
        type="fill"
        source="plots"
        layout={layerVisibility}
        paint={{
          'fill-color': riskOfDeforestationColor,
          'fill-opacity': [
            'case',
            ['==', ['get', 'plotId'], hoveredPlotId],
            0.2,
            0,
          ],
        }}
      />
      <Layer
        {...plotOutlineStyle}
        layout={layerVisibility}
        paint={{
          'line-color': riskOfDeforestationColor,
          'line-dasharray': [
            'case',
            ['==', ['get', 'plotId'], selectedPlotId],
            ['literal', [1]],
            ['literal', [2, 1]],
          ],
          'line-width': [
            'case',
            ['==', ['get', 'plotId'], selectedPlotId],
            2.5,
            3.5,
          ],
        }}
      />
    </Source>
  ) : null;
};

export default memo(PlotsLayer);
