import { Menu as MantineMenu, MenuProps } from '@mantine/core';
import classes from './Menu.module.css';

export const Menu = function (props: MenuProps) {
  return <MantineMenu {...props} radius="xs" classNames={{ ...classes }} />;
};

Menu.displayName = MantineMenu.displayName;
Menu.Item = MantineMenu.Item;
Menu.Label = MantineMenu.Label;
Menu.Dropdown = MantineMenu.Dropdown;
Menu.Target = MantineMenu.Target;
Menu.Divider = MantineMenu.Divider;
