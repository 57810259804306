import { Stack, Text } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { ErrorTemplate } from './ErrorTemplates/ErrorTemplate';
import { capitalizeFirstLetter } from '@liveeo/helpers';

type ErrorMessages = {
  errorDescription: string;
};

const InvitationDenied = () => {
  const { t } = useTranslation();

  return (
    <ErrorTemplate
      title={t<string>('invitationDenied.title')}
      actions={[
        { label: 'signup', primary: true },
        { label: 'backToLogin', primary: false },
      ]}
    >
      <Stack c="dark.9">
        <Text fz="sm">{t<string>('invitationDenied.body')}</Text>

        <Text fz="sm">
          {t<string>('contact.support', {
            emailAddress: 'support-tradeaware@live-eo.com',
          })}
        </Text>
      </Stack>
    </ErrorTemplate>
  );
};

const AccessDenied = () => {
  const { t } = useTranslation();
  return (
    <ErrorTemplate
      title={t<string>('accessDenied.title')}
      actions={[
        { label: 'backToLogin', primary: true },
        { label: 'reload', primary: false },
      ]}
    >
      <Stack gap="xl" c="dark.9">
        <Text fz="sm">{t<string>('accessDenied.body')}</Text>
        <Text fz="sm">
          {t<string>('accessDenied.support', {
            emailAddress: 'support-tradeaware@live-eo.com',
          })}
        </Text>
      </Stack>
    </ErrorTemplate>
  );
};

const GenericError = ({ errorDescription }: ErrorMessages) => {
  const { t } = useTranslation();
  return (
    <ErrorTemplate
      title={capitalizeFirstLetter(errorDescription)}
      actions={[
        { label: 'reload', primary: false },
        { label: 'backToLogin', primary: true },
      ]}
    >
      <Text fz="sm" c="dark.9">
        {t<string>('accessDenied.support', {
          emailAddress: 'support-tradeaware@live-eo.com',
        })}
      </Text>
    </ErrorTemplate>
  );
};

export { AccessDenied, InvitationDenied, GenericError };
