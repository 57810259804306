import { useTranslation } from 'react-i18next';
import { Modal, Stack, Text, Title } from '@liveeo/component-library';
import { InviteSuppliersForm } from './InviteSuppliersForm';

type InviteSuppliersProps = {
  isOpen: boolean;
  toggleOpen: () => void;
};

export const InviteSuppliers = ({
  isOpen,
  toggleOpen,
}: InviteSuppliersProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={
        <div>
          <Title order={3}>{t<string>('invite.suppliers.title')}</Title>
        </div>
      }
      opened={isOpen}
      onClose={() => {
        toggleOpen();
      }}
      centered
    >
      <Stack mb="sm">
        <Text>{t<string>('invite.onplatformSuppliers.text')}</Text>
        <InviteSuppliersForm cancel={toggleOpen} />
      </Stack>
    </Modal>
  );
};
