import { CustomSVGProps } from './CustomSVG.interface';

export const ResetView: React.FC<CustomSVGProps> = ({
  color = 'none',
  style,
  ...props
}) => {
  return (
    <svg
      width={style?.fontSize}
      height={style?.fontSize}
      style={style}
      fill={color}
      color={color}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fill="inherit"
        d="M5 18.2446C4.58579 18.2446 4.25 18.5803 4.25 18.9946C4.25 19.4088 4.58579 19.7446 5 19.7446V18.2446ZM4.24883 19C4.24883 19.4142 4.58461 19.75 4.99883 19.75C5.41304 19.75 5.74883 19.4142 5.74883 19H4.24883ZM18.2449 19C18.2449 19.4142 18.5807 19.75 18.9949 19.75C19.4091 19.75 19.7449 19.4142 19.7449 19H18.2449ZM18.9996 19.75C19.4138 19.75 19.7496 19.4142 19.7496 19C19.7496 18.5858 19.4138 18.25 18.9996 18.25V19.75ZM18.9977 5.75545C19.4119 5.75545 19.7477 5.41966 19.7477 5.00545C19.7477 4.59123 19.4119 4.25545 18.9977 4.25545V5.75545ZM19.7488 5.00004C19.7488 4.58583 19.413 4.25004 18.9988 4.25004C18.5846 4.25004 18.2488 4.58583 18.2488 5.00004H19.7488ZM5.75273 5.00004C5.75273 4.58583 5.41695 4.25004 5.00273 4.25004C4.58852 4.25004 4.25273 4.58583 4.25273 5.00004H5.75273ZM4.99805 4.25C4.58383 4.25 4.24805 4.58579 4.24805 5C4.24805 5.41421 4.58383 5.75 4.99805 5.75V4.25ZM9.2 18.2446H5V19.7446H9.2V18.2446ZM4.24883 14.8V19H5.74883V14.8H4.24883ZM18.2449 14.8V19H19.7449V14.8H18.2449ZM14.7996 19.75H18.9996V18.25H14.7996V19.75ZM14.7977 5.75545H18.9977V4.25545H14.7977V5.75545ZM19.7488 9.20004V5.00004H18.2488V9.20004H19.7488ZM5.75273 9.20004V5.00004H4.25273V9.20004H5.75273ZM9.19805 4.25H4.99805V5.75H9.19805V4.25ZM14.2488 12C14.2488 13.2426 13.2415 14.25 11.9988 14.25V15.75C14.0699 15.75 15.7488 14.0711 15.7488 12H14.2488ZM11.9988 14.25C10.7562 14.25 9.74883 13.2426 9.74883 12H8.24883C8.24883 14.0711 9.92776 15.75 11.9988 15.75V14.25ZM9.74883 12C9.74883 10.7574 10.7562 9.75 11.9988 9.75V8.25C9.92776 8.25 8.24883 9.92893 8.24883 12H9.74883ZM11.9988 9.75C13.2415 9.75 14.2488 10.7574 14.2488 12H15.7488C15.7488 9.92893 14.0699 8.25 11.9988 8.25V9.75Z"
      />
    </svg>
  );
};
