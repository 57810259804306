import mapboxgl from 'mapbox-gl';
import { useControl } from 'react-map-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useTracking } from '../../hooks/useTracking';
import DOMPurify from 'dompurify';

type Props = {
  position: string;
};

const GeoCoder = (props: any) => {
  const { trackEvent } = useTracking();
  const geocoder = new MapboxGeocoder(props);
  let timeoutId: any = null;
  geocoder.on('loading', ({ query }: { query: string }) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(() => {
      trackEvent('Map', {
        step: 'search-place',
        action: DOMPurify.sanitize(query),
      });
    }, 1000);
  });

  useControl(() => geocoder, {
    ...props,
    position: props.position,
    accessToken: props.accessToken,
  });
  return null;
};

// TOOD define a bbox for search query
export const GeoLocationSearch = ({ position }: Props) => {
  return (
    <GeoCoder
      marker={true}
      mapboxgl={mapboxgl}
      position={position}
      accessToken={import.meta.env['NX_EUDR_MAPBOX_ACCESS_TOKEN']}
    />
  );
};
