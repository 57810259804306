import { useState, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../../hooks';
import { useMediaQuery } from '@mantine/hooks';
import { Progress } from './Progress';
import { AccountDetails } from './AccountDetails';
import { BulkUploadPlots } from './BulkUploadPlots';
import { IntroText } from './IntroText';
import { ParsedFile } from '../../shared/types/bulk-upload';
import { Center, Grid, Logo, Stack, Text } from '@liveeo/component-library';
import classes from './SupplierOnboarding.module.css';

export const SupplierOnboarding = () => {
  const { t } = useTranslation();
  const { data: user, isError } = useUser();
  const largeScreen = useMediaQuery('(min-width: 992px)');
  const [active, setActive] = useState(0);
  const [plotsWithErrors, setPlotsWithErrors] = useState<ParsedFile[]>([]);
  const [successfulUploads, setSuccessfulUploads] = useState<ParsedFile[]>([]);
  const [showUploader, setShowUploader] = useState<boolean>(true);

  const nextStep = () =>
    setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () =>
    setActive((current) => (current > 0 ? current - 1 : current));

  const ONBOARDING_STEPS: Record<number, ReactNode> = {
    0: <AccountDetails nextStep={nextStep} />,
    1: (
      <BulkUploadPlots
        prevStep={prevStep}
        plotsWithErrors={plotsWithErrors}
        setPlotsWithErrors={setPlotsWithErrors}
        successfulUploads={successfulUploads}
        setSuccessfulUploads={setSuccessfulUploads}
        showUploader={showUploader}
        setShowUploader={setShowUploader}
      />
    ),
  };

  return isError ? (
    <Center h="100%">
      <Text>{t('error.user')}</Text>
    </Center>
  ) : (
    <div className={classes.outerContainer}>
      <div className={classes.backgroundImg} />
      <div
        className={classes.scrollContainer}
        style={{
          width: largeScreen ? '70%' : '95%',
        }}
      >
        <Grid h="100%">
          <Grid.Col className={classes.leftCol} span={{ sm: 0, md: 4 }}>
            <Stack gap={60} mt={30} p={60}>
              <Logo width={150} color="white" />
              {active === 0 ? <IntroText /> : <Progress active={active} />}
            </Stack>
          </Grid.Col>
          <Grid.Col
            span={{ sm: 12, md: 8 }}
            style={{ backgroundColor: '#fff' }}
          >
            <Stack m={largeScreen ? 60 : 40}>
              <Stack gap="xl">{user ? ONBOARDING_STEPS[active] : null}</Stack>
            </Stack>
          </Grid.Col>
        </Grid>
      </div>
    </div>
  );
};
