import React from 'react';
import {
  useMantineTheme,
  DefaultMantineColor,
  MantineColorsTuple,
} from '@mantine/core';
import { IconAliases } from './IconsList';

export interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  icon: keyof typeof IconAliases;
  color?: keyof typeof useMantineTheme | string;
  size?: 'sm' | 'md' | 'lg' | 'xl' | 'xxl';
  style?: React.CSSProperties;
}

const sizeMapper: Record<string, number> = {
  sm: 16,
  md: 24,
  lg: 28,
  xl: 32,
  xxl: 40,
};

export const Icon: React.FC<IconProps> = ({
  size = 'md',
  icon: iconName,
  color = '',
  ...props
}) => {
  const theme = useMantineTheme();

  const [c = '', shade = '']: any = color
    ? (color.split('.') as [DefaultMantineColor, keyof MantineColorsTuple])
    : ['', ''];

  const IconComponent = IconAliases[iconName];

  if (!IconComponent) {
    console.error(`Icon "${iconName}" not found in IconAliases`);
    return null;
  }

  return (
    <IconComponent
      style={{
        fontSize: sizeMapper[size],
        strokeWidth: '1.5',
      }}
      color={theme?.colors[c]?.[shade] || color}
      {...props}
    />
  );
};
