import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';
import {
  AccessDenied,
  InvitationDenied,
  GenericError,
} from './shared/components/Auth0AccessDenied';
import { UnverifiedEmailError } from './shared/components/ErrorTemplates/UnverifiedEmailError';
import * as Sentry from '@sentry/react';

export const Auth0LoginRedirect = () => {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const queryParams = new URLSearchParams(window.location.search);
  const { isLoading, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const invitationId = queryParams.get('invitation');
  const organizationId = queryParams.get('organization');
  const errorDescription = queryParams.get('error_description');
  const organizationName = queryParams.get('organization_name');
  const error = queryParams.get('error');
  const invitationDenied =
    errorDescription ===
    'the specified account is not allowed to accept the current invitation';
  const unverifiedEmail =
    errorDescription === 'Please verify your email before logging in.';
  const accessDenied = error === 'access_denied';

  useEffect(() => {
    if (!error) {
      if (!isLoading && isAuthenticated && invitationId) {
        logout({
          logoutParams: {
            returnTo: `${window.location.origin}/login?invitation=${invitationId}&organization=${organizationId}&organization_name=${organizationName}`,
          },
        });
      } else if (!isLoading && !isAuthenticated) {
        if (invitationId && organizationId) {
          loginWithRedirect({
            authorizationParams: {
              invitation: invitationId,
              organization: organizationId,
              ui_locales:
                i18n.languages.length > 0 ? i18n.languages.join(' ') : 'en',
            },
          });
        } else {
          loginWithRedirect();
        }
      }
    }
    if (error) {
      Sentry.captureException(error);
    }
  }, [
    loginWithRedirect,
    organizationId,
    invitationId,
    organizationName,
    isAuthenticated,
    isLoading,
    logout,
    error,
    Sentry,
  ]);

  return error ? (
    invitationDenied ? (
      <InvitationDenied />
    ) : accessDenied ? (
      unverifiedEmail ? (
        <UnverifiedEmailError />
      ) : (
        <AccessDenied />
      )
    ) : (
      <GenericError
        errorDescription={errorDescription || t('An error has occurred')}
      />
    )
  ) : null;
};
