import { useQueryParams, BooleanParam, withDefault } from 'use-query-params';

export const PlotsParam = withDefault(BooleanParam, true);
export const OpenSourceParam = withDefault(BooleanParam, true);

export const useLayerState = () => {
  const [query, setQuery] = useQueryParams<{
    gFC2020: typeof OpenSourceParam;
    plots: typeof PlotsParam;
  }>();

  return {
    layerState: {
      gFC2020: query.gFC2020,
      plots: query.plots,
    },
    setLayerState: (stateUpdate: object) => {
      setQuery({ ...stateUpdate });
    },
  };
};
