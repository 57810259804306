import React from 'react';
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { useMantineTheme } from '@mantine/core';

export type IconProps = Omit<FontAwesomeIconProps, 'icon'> & {
  icon: IconName | [IconPrefix, IconName];
};

export const Icon = ({ icon, color, ...props }: IconProps) => {
  const theme = useMantineTheme();
  const [c, shade] = color ? color.split('.') : [];
  const _icon = Array.isArray(icon)
    ? (icon as [IconPrefix, IconName])
    : ['fas', icon as IconName];
  const _color = c
    ? c.includes('#')
      ? color
      : theme.colors?.[c]?.[(shade || theme.primaryShade) as any]
    : color;
  return (
    <FontAwesomeIcon
      icon={_icon as [IconPrefix, IconName]}
      {...props}
      color={_color}
    />
  );
};
