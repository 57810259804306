import { GenericErrorBoundary } from '../../../shared/components/GenericErrorBoundary';
import {
  Accordion,
  Button,
  Flex,
  Stack,
  Text,
} from '@liveeo/component-library';
import { useToggle } from '@liveeo/helpers';
import classes from '../Admin.module.css';
import { useTranslation } from 'react-i18next';
import { RotateConfirmationModal } from './RotateConfirmationModal';

type RotateSecretProps = {
  clientId: string | undefined;
};

export const RotateSecret = ({ clientId }: RotateSecretProps) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useToggle();
  return (
    <GenericErrorBoundary>
      <Accordion classNames={{ item: classes['item'] }}>
        <Accordion.Item
          value={clientId || 'default value'}
          key={clientId || 'default value'}
        >
          <Accordion.Control>
            <Text size="md">
              {t<string>('devTools.rotate.accordianDanger')}
            </Text>
          </Accordion.Control>
          <Accordion.Panel>
            <Stack gap="md">
              <Text size="lg" fw="bolder" mt="md">
                {t<string>('devTools.rotate.accordianTitle')}
              </Text>
              <Flex justify="space-between">
                <Text>{t<string>('devTools.rotateText')}</Text>
                <Button
                  variant="outline"
                  className={classes['rotate-button']}
                  onClick={() => setShowModal()}
                >
                  {t<string>('devTools.rotateButton')}
                </Button>
              </Flex>
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
      <RotateConfirmationModal
        isOpen={showModal}
        cancel={setShowModal}
        clientId={clientId}
      />
    </GenericErrorBoundary>
  );
};
