import { Tooltip, TooltipProps } from '@mantine/core';
import { ReactNode } from 'react';
import classes from './StyledTooltip.module.css';

interface StyledTooltipProps extends TooltipProps {
  dark?: boolean;
  children: ReactNode;
}

export const StyledTooltip: React.FC<StyledTooltipProps> = ({
  children,
  dark = false,
  ...props
}) => {
  return (
    <Tooltip
      {...props}
      color={dark ? '#1F1D22' : undefined}
      className={classes['root']}
    >
      {children}
    </Tooltip>
  );
};
