import { PropsWithChildren } from 'react';
import { ActionIcon, Flex, Icon, Menu } from '@liveeo/component-library';
import classes from '../Admin.module.css';

type MenuItemProps = PropsWithChildren<{
  onClick?: () => void;
}>;

const MenuItem = ({ children, onClick }: MenuItemProps) => {
  return (
    <Menu.Item
      component="span"
      onClick={onClick}
      className={classes.invitationMenuItem}
    >
      {children}
    </Menu.Item>
  );
};

export const InvitationActionsMenu = ({ children }: PropsWithChildren) => (
  <Flex justify="flex-end">
    <Menu withArrow position="bottom-end" withinPortal>
      <Menu.Target>
        <ActionIcon
          size="sm"
          color="transparent"
          onClick={(e: any) => e.stopPropagation()}
        >
          <Icon icon="ellipsis-vertical" color="dark.0" />
        </ActionIcon>
      </Menu.Target>
      <Menu.Dropdown
        bg="dark.7"
        onClick={(e: any) => e.stopPropagation()}
        className={classes.invitationMenu}
      >
        {children}
      </Menu.Dropdown>
    </Menu>
  </Flex>
);

InvitationActionsMenu.MenuItem = MenuItem;
