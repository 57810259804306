import { useState } from 'react';
import {
  Button,
  Group,
  Modal,
  Stack,
  Text,
  Flex,
  TextInput,
} from '@liveeo/component-library';
import classes from '../Admin.module.css';
import { useTranslation } from 'react-i18next';
import { GenericErrorBoundary } from '../../../shared/components/GenericErrorBoundary';
import { useM2MClientSecret } from '../../../hooks';

type Props = {
  clientId: string | undefined;
  isOpen: boolean;
  cancel: () => void;
};

export const RotateConfirmationModal = ({
  isOpen,
  cancel,
  clientId,
}: Props) => {
  const { t } = useTranslation();
  const { rotateSecret } = useM2MClientSecret(clientId || '');
  const [copiedClientId, setCopiedClientId] = useState<string>();

  return (
    <GenericErrorBoundary>
      <Modal
        opened={isOpen}
        onClose={cancel}
        title={
          <Text size="xl" fw="bold">
            {t<string>('rotate.confirmationTitle')}
          </Text>
        }
        centered
      >
        <Stack gap={30} align="center">
          <Text>{t<string>('rotate.confirmationText')}</Text>
          <Flex direction="column" justify="center">
            <Text>{clientId}</Text>
          </Flex>
          <TextInput
            label={<Text size="sm">{t<string>('common.clientId')}</Text>}
            value={copiedClientId}
            onChange={(event) => setCopiedClientId(event.currentTarget.value)}
            w="90%"
            classNames={{ input: classes.input }}
          />
          <Group justify="flex-end" w="100%">
            <Button
              variant="outline"
              onClick={() => {
                cancel();
                setCopiedClientId('');
              }}
            >
              {t<string>('common.cancel')}
            </Button>
            <Button
              onClick={() => {
                rotateSecret(clientId || '');
                cancel();
                setCopiedClientId('');
              }}
              disabled={clientId !== copiedClientId}
            >
              {t<string>('devTools.rotateButton')}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </GenericErrorBoundary>
  );
};
