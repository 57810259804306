import { Grid, Flex, Title, Button } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { useToggle } from '@liveeo/helpers';
import { UserProfileWrapper } from './UserProfileWrapper';
import { useFlags } from '../../contexts/FlagsContext';
import { useUser } from '../../hooks';
import classes from './Admin.module.css';
import { InvitationsTable } from './Invitations/InvitationsTable';
import { InviteMembers } from './Invitations/InviteMembersModal';
import { GenericErrorBoundary } from '../../shared/components/GenericErrorBoundary';
import { invitations } from './dummyData';
import {
  BooleanParam,
  NumberParam,
  QueryParamProvider,
  StringParam,
} from 'use-query-params';

type MembersBarProps = {
  setAddMembersModalOpen: () => void;
};

const MembersBar = ({ setAddMembersModalOpen }: MembersBarProps) => {
  const { data: user, isLoading } = useUser();

  const { t } = useTranslation();
  return isLoading ? null : (
    <Flex justify="space-between" align="center">
      <Title ml="lg" my="lg" order={2} className={classes.barTitle}>
        {user?.business.name}
      </Title>
      <Button
        variant="filled"
        size="md"
        onClick={setAddMembersModalOpen}
        className={classes.barButton}
      >
        {t('admin.addMembers')}
      </Button>
    </Flex>
  );
};

export const AdminPage = () => {
  const [addMembersModalOpen, setAddMembersModalOpen] = useToggle();
  const { COMMERCIAL_GRAPH_v2 } = useFlags();

  return (
    <QueryParamProvider
      options={{
        params: {
          pg: NumberParam,
          sel: StringParam,
          ex: NumberParam,
          sort: StringParam,
          desc: BooleanParam,
        },
        removeDefaultsFromUrl: false,
        updateType: 'replaceIn',
      }}
    >
      {/* eslint-disable-next-line react/jsx-no-useless-fragment */}
      <>
        {COMMERCIAL_GRAPH_v2 ? (
          <GenericErrorBoundary title="Unable to display admin page">
            <Flex w="100%" justify="center" align="center">
              <Grid className={classes.root}>
                <Grid.Col
                  span={{ sm: 12, lg: 'content' }}
                  className={classes.col}
                  mr="sm"
                >
                  <UserProfileWrapper />
                </Grid.Col>
                <Grid.Col span="auto" className={classes.col}>
                  <MembersBar setAddMembersModalOpen={setAddMembersModalOpen} />
                  <InvitationsTable invitations={invitations} />
                </Grid.Col>
              </Grid>
              <InviteMembers
                isOpen={addMembersModalOpen}
                toggleOpen={setAddMembersModalOpen}
              />
            </Flex>
          </GenericErrorBoundary>
        ) : (
          <GenericErrorBoundary>
            <Flex w="100%" justify="center" align="center">
              <UserProfileWrapper />
            </Flex>
          </GenericErrorBoundary>
        )}
      </>
    </QueryParamProvider>
  );
};
