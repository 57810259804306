import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from '@tanstack/react-query';
import moment from 'moment';
import {
  Button,
  Divider,
  Text,
  Stack,
  Center,
  Loader,
  Flex,
  Icon,
  ActionIcon,
} from '@liveeo/component-library';
import { NoNotifications } from './NoNotifications';
import { useInvitedBusinesses } from '../../hooks/useInvitedBusinesses';
import { useInvitationStatus } from '../../hooks/useInvitationStatus';
import { InvitedSupplier, InvitationStatus } from '../../shared/types';
import classes from './Notifications.module.css';
import { heightOfSidePanelContent } from '../../helper/side-panel-height';
import { useTracking } from '../../hooks';

type InvitationProps = {
  id: string;
  email: string;
  createdAt: string;
};

enum SideEffects {
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
}

const Invitation = ({ id, email, createdAt }: InvitationProps) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { trackEvent } = useTracking();
  const { status } = useInvitationStatus();
  const { updateInvitation } = useInvitedBusinesses();
  const [sideEffect, setSideEffect] = useState<SideEffects>(
    SideEffects.PENDING
  );

  return (
    <>
      {sideEffect === SideEffects.FAILED ? (
        <Flex align="center" justify="space-between" p={15} h={135}>
          <Text>{t('notifications.supplier.retry')}</Text>
          <Button
            w={100}
            variant="outline"
            className={classes.button}
            onClick={async () => {
              try {
                await updateInvitation({
                  id,
                  update: { status: InvitationStatus.CANCELLED },
                });
                setSideEffect(SideEffects.CANCELLED);
                trackEvent('Notifications', {
                  step: 'connection-sent',
                  action: 'cancel',
                });
              } catch (e: any) {
                console.log('error cancelling invitations', e.message);
              }
            }}
          >
            {t('common.retry')}
          </Button>
        </Flex>
      ) : sideEffect === SideEffects.CANCELLED ? (
        <Flex align="center" justify="space-between" p={15} h={135}>
          <Text>
            {t('notifications.supplier.withdrawn')} <b>{email}</b>
          </Text>
          <ActionIcon
            className={classes['cancel-btn']}
            onClick={() => {
              queryClient.setQueryData(
                ['invited-businesses', status],
                (prevInvites: InvitedSupplier[] | undefined) =>
                  prevInvites?.filter(
                    (invite: InvitedSupplier) => invite.id !== id
                  )
              );
            }}
          >
            <Icon icon="xmark" />
          </ActionIcon>
        </Flex>
      ) : (
        <Stack gap={5} p={15} h={135}>
          <Text mb={10}>
            {t('notifications.supplier.request')} <b>{email}</b>
          </Text>
          <Stack gap="sm">
            <Text size="xs">{moment(createdAt).fromNow()}</Text>
            <Button
              w={100}
              variant="outline"
              className={classes.button}
              onClick={async () => {
                try {
                  await updateInvitation({
                    id,
                    update: { status: InvitationStatus.CANCELLED },
                  });
                  setSideEffect(SideEffects.CANCELLED);
                  trackEvent('Notifications', {
                    step: 'connection-sent',
                    action: 'cancel',
                  });
                } catch (e: any) {
                  setSideEffect(SideEffects.FAILED);
                  console.log('error cancelling invitations', e.message);
                }
              }}
            >
              {t('common.cancel')}
            </Button>
          </Stack>
        </Stack>
      )}
      <Divider />
    </>
  );
};

export const SentInvitations = () => {
  const { status } = useInvitationStatus();
  const { data: invitations, isLoading } = useInvitedBusinesses(
    status || undefined
  );
  const isEmpty = !isLoading && !invitations?.length;

  return (
    <>
      {isLoading ? (
        <Center h={heightOfSidePanelContent}>
          <Loader />
        </Center>
      ) : isEmpty ? (
        <NoNotifications />
      ) : (
        <>
          {invitations?.map((invite: InvitedSupplier) => (
            <Invitation
              key={invite.id}
              id={invite.id}
              email={invite.email}
              createdAt={invite.createdAt}
            />
          ))}
        </>
      )}
    </>
  );
};
