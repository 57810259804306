import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useUser } from '../../hooks';
import {
  Flex,
  Button,
  Grid,
  Stack,
  Title,
  Text,
  Image,
} from '@liveeo/component-library';
import {
  deforestationDisturbance,
  deforestationRiskMapping,
} from '../../helper';
import SUPPLIERS_ICON from '../../../assets/suppliers-icon.svg';
import classes from './CollapsedRow.module.css';
import { useResetParams } from '../../hooks/useRestParams';

type Props = {
  riskScore: number | undefined;
  supplierName: string;
  supplierId: string | null;
  plotId: string;
  isSupplierView: boolean;
};

export const CollapsedRow = ({
  riskScore,
  supplierName,
  supplierId,
  plotId,
  isSupplierView,
}: Props) => {
  const { t } = useTranslation();
  const { data: user } = useUser();
  const { resetParams } = useResetParams();

  return supplierId && plotId ? (
    <Stack className={classes.root}>
      <Text size="sm" fs="italic">
        {t('analysis.title')}
      </Text>
      <Grid grow>
        <Grid.Col span={4}>
          <Stack>
            <Title order={6}>{t('analysis.layers.precision')}</Title>
            <Title order={6}>{t('analysis.layers.global')}</Title>
          </Stack>
        </Grid.Col>
        <Grid.Col span={4}>
          <Stack>
            <Text>{t('analysis.purchase')}</Text>
            <Text>
              {t(`analysis.disturbance`, {
                percentage: deforestationDisturbance(riskScore),
              })}
            </Text>
          </Stack>
        </Grid.Col>
        <Grid.Col span={4}>
          <Stack>
            <Button
              size="compact-sm"
              to="https://live-eo.com/tradeaware-contact"
              component={Link}
              target="_blank"
            >
              {t<string>('analysis.specialist')}
            </Button>
            <Text
              className={classes.text}
              data-hidden={typeof riskScore === 'undefined'}
            >
              {t(`common.risk.${deforestationRiskMapping(riskScore)}`)}
            </Text>
          </Stack>
        </Grid.Col>
      </Grid>
      {!isSupplierView && supplierId !== user?.businessId && (
        <Button
          leftSection={<Image src={SUPPLIERS_ICON} w={15} />}
          className={classes.button}
          component={Link}
          to={`../suppliers/${supplierId}?${resetParams()}&sel=${plotId}`}
        >
          {supplierName}
        </Button>
      )}
    </Stack>
  ) : null;
};
