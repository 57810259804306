import { Radio as MantineRadio, RadioProps } from '@mantine/core';
import { withRef } from '../../helpers/typescript';
import classes from './Radio.module.css';

const _Radio = ({ ...props }: RadioProps, ref: any) => {
  return <MantineRadio {...props} classNames={{ ...classes }} ref={ref} />;
};

export const Radio = withRef<HTMLElement, RadioProps, typeof MantineRadio>({
  component: _Radio,
});

Radio['Group'] = MantineRadio.Group;
