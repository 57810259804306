import { useForm, useFieldArray } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import Joi from 'joi';
import {
  TextInput,
  Stack,
  Flex,
  ActionIcon,
  Icon,
  Button,
} from '@liveeo/component-library';

import classes from '../Admin.module.css';

type InviteUserProps = {
  cancel: () => void;
};

const InviteSchema = Joi.object().keys({
  users: Joi.array().items(
    Joi.object().keys({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
    })
  ),
});

const defaultValues = {
  email: '',
};

export const InviteUser = ({ cancel }: InviteUserProps) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { isSubmitSuccessful, errors },
  } = useForm({
    defaultValues: {
      users: [{ ...defaultValues }],
    },
    resolver: joiResolver(InviteSchema),
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const onSubmit = (data: any) => {
    console.log(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Stack>
        {fields.map((field, index) => (
          <Flex
            key={field.id}
            justify="space-between"
            align="center"
            direction="row"
          >
            <TextInput
              {...register(`users.${index}.email`)}
              error={errors?.users?.[index]?.email?.message}
              w="90%"
              classNames={{ input: classes.input }}
            />
            {fields.length > 1 ? (
              <ActionIcon size="md" onClick={() => remove(index)}>
                <Icon icon="trash" />
              </ActionIcon>
            ) : (
              <ActionIcon
                size="md"
                onClick={() => append({ ...defaultValues })}
              >
                <Icon icon="plus" />
              </ActionIcon>
            )}
          </Flex>
        ))}
        {fields.length > 1 && (
          <Flex w="100%" justify="flex-end">
            <ActionIcon size="md" onClick={() => append({ ...defaultValues })}>
              <Icon icon="plus" />
            </ActionIcon>
          </Flex>
        )}
      </Stack>
      <Flex justify="end" gap="sm" mt="sm">
        <Button variant="outline" size="sm" onClick={cancel}>
          Cancel
        </Button>
        <Button size="sm" type="submit">
          Invite
        </Button>
      </Flex>
    </form>
  );
};
