import { create } from 'zustand';

type State = {
  hoveredPlotId: string | null;
};

type Action = {
  setHoveredPlotId: (hoveredPlotId: string | null) => void;
};

export const useHoveredPlotId = create<State & Action>((set) => ({
  hoveredPlotId: null,
  setHoveredPlotId: (hoveredPlotId: string | null) =>
    set(() => ({ hoveredPlotId })),
}));
