import { useLocation } from 'react-router-dom';

export const useResetParams = () => {
  const location = useLocation();

  const resetParams = () => {
    const searchParams = new URLSearchParams(location.search);
    // reset table state when changing route
    searchParams.set('pg', '0');
    searchParams.set('pan', '1');
    searchParams.delete('sel');
    searchParams.delete('ex');
    searchParams.delete('sort');
    searchParams.delete('desc');
    searchParams.delete('status');
    return `${searchParams.toString()}`;
  };

  return { resetParams };
};
