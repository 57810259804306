import { Source, Layer, useMap } from 'react-map-gl';
import { useLayerState } from '../../hooks';

const rasterSource = {
  id: 'global-forest-layer',
  type: 'raster',
  tiles: [
    'https://ies-ows.jrc.ec.europa.eu/iforce/gfc2020/wms.py?layers=gfc2020&bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.1.1&request=GetMap&srs=EPSG:3857&width=256&height=256&bgcolor=0xFF0000',
  ],
  tileSize: 256,
};

export const GlobalForestLayer = () => {
  const { layerState } = useLayerState();
  const { deforestationMap: map } = useMap();

  return map?.getMap() ? (
    <>
      <Source
        id="global-forest-layer"
        type="raster"
        tiles={rasterSource.tiles}
        tileSize={rasterSource.tileSize}
      />
      <Layer
        type="raster"
        layout={{
          visibility: layerState.gFC2020 ? 'visible' : 'none',
        }}
        paint={{
          'raster-opacity': 0.3,
          'raster-contrast': 0.2, // Increase or decrease to adjust contrast
          'raster-brightness-min': 0, // Increase or decrease to adjust brightness
          'raster-brightness-max': 1, // Increase or decrease to adjust brightness
          'raster-saturation': 0.7, // Increase or decrease to adjust saturation
        }}
        source="global-forest-layer"
      />
    </>
  ) : null;
};
