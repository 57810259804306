import { useTranslation } from 'react-i18next';
import { Stack, Title, Text, HoverCard, List } from '@liveeo/component-library';
import { useMantineTheme } from '@liveeo/helpers';

export const IntroText = () => {
  const { t } = useTranslation();
  const theme: any = useMantineTheme();

  return (
    <Stack gap="lg" w={210} c="#fff">
      <Title order={1} fw={600}>
        {t('profile.onboarding.title')}
      </Title>
      <Stack gap="xs">
        <Text fw={400}>{t('profile.onboarding.text')}</Text>
        <HoverCard width={300} shadow="md" withArrow>
          <HoverCard.Target>
            <Text fw={600} c="#805BA0">
              {t('profile.onboarding.tips.upload.tooltip')}
            </Text>
          </HoverCard.Target>
          <HoverCard.Dropdown bg={theme.white}>
            <Stack c="#1F1D22">
              <Text size="sm">{t('profile.onboarding.tips.upload.title')}</Text>
              <Text size="sm">{t('profile.onboarding.tips.upload.text')}</Text>
              <List size="sm" c={theme.colors.dark}>
                <List.Item>
                  {t('profile.onboarding.tips.upload.formats.geoJson')}
                </List.Item>
              </List>
            </Stack>
          </HoverCard.Dropdown>
        </HoverCard>
      </Stack>
    </Stack>
  );
};
