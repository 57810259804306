import { Center, Title, Button, Group, Stack } from '@liveeo/component-library';
import { PropsWithChildren, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

type ActionLabel = 'reload' | 'logout' | 'backToLogin' | 'signup';

type Action = {
  label: ActionLabel;
  primary: boolean;
};

type Props = {
  title: string;
  actions?: Action[];
  customAction?: ReactElement;
  color?: string | null;
};

function sortByPrimary(a: Action, b: Action) {
  if (a.primary === b.primary) {
    return a.label.localeCompare(b.label);
  }
  return a.primary ? 1 : -1;
}

const ErrorTemplate = ({
  children,
  title,
  actions = [],
  customAction,
  color = null,
}: PropsWithChildren<Props>) => (
  <Center bg="dark.7">
    <Stack bg={color ?? 'dark.0'} p="md" mt="10vh" w="500px">
      <Title my="xs" mb="sm" order={4} c="dark.9">
        {title}
      </Title>
      {children}
      <Group justify="flex-end">
        {actions.sort(sortByPrimary).map((action, index) => {
          if (action.label === 'logout') {
            return (
              <LogoutButton
                primary={action.primary}
                label={action.label}
                key={action.label + index}
              />
            );
          }
          if (action.label === 'signup') {
            return (
              <SignupButton
                primary={action.primary}
                label={action.label}
                key={action.label + index}
              />
            );
          }
          if (action.label === 'backToLogin') {
            return (
              <BackToLoginButton
                primary={action.primary}
                label={action.label}
                key={action.label + index}
              />
            );
          }
          if (action.label === 'reload') {
            return (
              <ReloadButton
                primary={action.primary}
                label={action.label}
                key={action.label + index}
              />
            );
          }
          return null;
        })}

        {customAction}
      </Group>
    </Stack>
  </Center>
);

const ReloadButton = ({ primary }: Action) => {
  const { t } = useTranslation();
  return (
    <Button
      size="xs"
      onClick={() => window.location.reload()}
      variant={primary ? 'filled' : 'outline'}
      c={primary ? 'dark.0' : 'purple.4'}
    >
      {t<string>('error.reload')}
    </Button>
  );
};

const LogoutButton = ({ primary }: Action) => {
  const { logout } = useAuth0();
  const { t } = useTranslation();
  return (
    <Button
      ml="sm"
      variant={primary ? 'filled' : 'outline'}
      component="a"
      size="xs"
      onClick={() => logout()}
      c={primary ? 'dark.0' : 'purple.4'}
    >
      {t<string>('error.logout')}
    </Button>
  );
};

const BackToLoginButton = ({ primary }: Action) => {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  return (
    <Button
      size="xs"
      onClick={() =>
        logout({
          logoutParams: {
            returnTo: window.location.origin,
          },
        })
      }
      variant={primary ? 'filled' : 'outline'}
      c={primary ? 'dark.0' : 'purple.4'}
    >
      {t<string>('error.backToLogin')}
    </Button>
  );
};

const SignupButton = ({ primary }: Action) => {
  const { t } = useTranslation();
  return (
    <Button
      size="xs"
      variant={primary ? 'filled' : 'outline'}
      component="a"
      href="https://www.live-eo.com/solution/eudr-compliance"
      c={primary ? 'dark.0' : 'purple.4'}
    >
      {t<string>('common.signup')}
    </Button>
  );
};

export { ErrorTemplate };
