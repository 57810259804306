import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { ForwardedRef, forwardRef } from 'react';

/**
 * Forwards a ref along with specified prop and component types.
 * This is useful when you are attempting to forward a ref to an
 * existing component but want to pass along all type information too.
 *
 * Example:
 * You are wrapping a Button from a ui component library with
 * custom styles. However the consuming component needs access to the button's ref.
 * Simply forwarding a ref will strip the original component's types from your
 * wrapper component. Using this function allows you to forward types along with
 * the ref.
 */

type WithRefArgs<Props, ElementType> = {
  component: (
    props: Props,
    ref: ForwardedRef<ElementType>
  ) => EmotionJSX.Element;
};

export const withRef = <ElementType, Props, AssertedComponent>({
  component: Component,
}: WithRefArgs<Props, ElementType>) =>
  forwardRef(Component) as unknown as (
    props: Props & { ref?: React.ForwardedRef<ElementType> }
  ) => ReturnType<any> as AssertedComponent;
