import { PropsWithChildren } from 'react';
import { Icon } from '../Icon';
import { SortDirection } from './types';
import { useMantineTheme } from '@mantine/core';
import { useTableContext } from './TableContext';
import classes from './Header.module.css';

export const Header = ({ children }: PropsWithChildren) => {
  const { scrolled } = useTableContext();
  return (
    <thead data-scrolled={scrolled} className={classes['header']}>
      {children}
    </thead>
  );
};

export const HeaderRow = ({ children, ...props }: PropsWithChildren) => (
  <tr {...props} className={classes['row']}>
    {children}
  </tr>
);

type CellProps = {
  className?: string;
  onClick?: (() => void) | any;
  sortingDirection?: SortDirection;
  darkMode?: boolean;
};

export const HeaderCell = ({
  className,
  children,
  onClick,
  sortingDirection,
  darkMode = false,
}: PropsWithChildren<CellProps>) => {
  let title = 'Sort ascending';

  if (sortingDirection === 'asc') {
    title = 'Sort descending';
  } else if (sortingDirection === 'desc') {
    title = 'Disable sorting';
  }

  return (
    <th
      className={classes['cell']}
      onClick={onClick}
      title={title}
      style={{
        ...(darkMode && {
          backgroundColor: '#1f1d22',
          color: '#dee2e6',
        }),
      }}
    >
      <div className={classes['cellContent']}>{children}</div>
    </th>
  );
};

export const HeaderTitle = ({ children, ...props }: PropsWithChildren) => (
  <p {...props} className={classes['title']}>
    {children}
  </p>
);

type SortIconProps = {
  color: string;
  direction?: SortDirection;
};

const SortIcon = ({ color = '#000', direction }: SortIconProps) => {
  const rotation = direction === 'desc' ? 180 : undefined;
  return <Icon icon="arrow-up" color={color} rotation={rotation} />;
};

type SortButtonProps = {
  direction?: SortDirection;
};

export const SortButton = ({ direction, ...props }: SortButtonProps) => {
  const theme = useMantineTheme();
  return (
    <button
      {...props}
      className={classes['sortButton']}
      data-active={!!direction}
    >
      <SortIcon color={theme.colors.dark[7]} direction={direction} />
    </button>
  );
};
