export const readableId = (str: string) => {
  const parts = str.split('-');
  let result = '';
  if (parts.length > 1) {
    result = `${parts[0]}-${parts[1]}-${parts[2]}`;
  } else {
    result = str;
  }
  if (result.length > 14) {
    result = `${str.slice(0, 11)}...`;
  }
  return result;
};
