import { PropsWithChildren } from 'react';
import { Button, Menu } from '@mantine/core';
import { Icon } from '../Icon';

type Props = PropsWithChildren<{ name: string; withinPortal?: boolean }>;

export const ButtonWithMenu = ({
  children,
  name,
  withinPortal = true,
}: Props) => {
  return (
    <Menu position="bottom-end" width={130} withinPortal={withinPortal}>
      <Menu.Target>
        <Button rightSection={<Icon icon="chevron-down" />}>{name}</Button>
      </Menu.Target>
      <Menu.Dropdown>{children}</Menu.Dropdown>
    </Menu>
  );
};

ButtonWithMenu.MenuItem = Menu.Item;
