import { PropsWithChildren } from 'react';
import {
  Button,
  Flex,
  Stack,
  TextInput,
  MultiSelect,
  Dialog,
  Text,
} from '@liveeo/component-library';
import { useMantineTheme } from '@liveeo/helpers';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Commodity } from '../../schema/supplier';
import { AddPlotSchema } from '../../schema/add-plot';
import { AddPlot, Geometry } from '../../shared/types';
import { Commodities } from '../../shared/types';
import { useTracking } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';

type CreateFormProps = {
  onDeletePlot: () => void;
  features: Geometry | any;
  createPlot: (p: AddPlot) => void;
  opened: boolean;
};

export const CreatePlotForm = ({
  onDeletePlot,
  features,
  createPlot,
  opened,
}: CreateFormProps) => {
  const { t } = useTranslation();
  const theme = useMantineTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AddPlot>({
    defaultValues: {
      name: '',
      commodities: [] as Commodity[],
      isActive: true,
    },
    resolver: yupResolver(AddPlotSchema),
  });
  const { trackEvent } = useTracking();

  const translatedCommodities = Object.entries(Commodities).map(
    ([label, value]) => ({
      value,
      label: t<string>(`common.commodities.${label}`),
    })
  );

  const onSubmit = (data: AddPlot) => {
    trackEvent('Map', {
      step: 'create-plot-form',
      action: 'submit',
    });
    createPlot({ ...data, geometry: features.geometry });
    onDeletePlot();
  };

  const handleCreateReset = () => {
    onDeletePlot();
    navigate(
      {
        pathname: '/map/plots',
        search: location.search,
      },
      { replace: true }
    );
  };

  return (
    <Dialog
      opened={opened}
      onClose={handleCreateReset}
      position={{ bottom: 200, right: 20 }}
      withCloseButton
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack justify="space-between">
          <Stack gap="lg">
            <TextInput
              {...register('name')}
              withAsterisk
              label={
                <Text span c="white">
                  {t<string>('map.sidePanel.details.fields.name')}
                </Text>
              }
              error={errors?.name?.message}
            />
            <Controller
              name="commodities"
              control={control}
              render={({ field }) => (
                <MultiSelect
                  {...field}
                  searchable
                  withAsterisk
                  nothingFoundMessage={
                    <Text c={theme.white}>{t<string>('common.nothing')}</Text>
                  }
                  label={t<string>('map.sidePanel.details.fields.commodities')}
                  error={errors?.commodities?.message}
                  data={translatedCommodities}
                />
              )}
            />
          </Stack>
          <Flex gap="lg" justify="center">
            <Button variant="outline" onClick={handleCreateReset}>
              {t<string>('common.cancel')}
            </Button>
            <Button type="submit">{t<string>('common.save')}</Button>
          </Flex>
        </Stack>
      </form>
    </Dialog>
  );
};
