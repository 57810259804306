import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AppShell,
  Center,
  Divider,
  Logo,
  Stack,
  Image,
  UnstyledButton,
  Tooltip,
  Beta,
} from '@liveeo/component-library';
import { Outlet, Link, NavLink, useLocation } from 'react-router-dom';
import { Profile } from '../../../components/AppMenubar/Profile';
import PLOTS_ICON from '../../../../assets/map.svg';
import SUPPLIERS_ICON from '../../../../assets/suppliers-icon.svg';
import BUYERS_ICON from '../../../../assets/buyer-icon.svg';
import { useFlags } from '../../../contexts/FlagsContext';
import { useResetParams } from '../../../hooks/useRestParams';
import classes from './main-layout.module.css';
import { useBusinessInvitations } from '../../../hooks/useBusinessInvitations';
import { InvitationStatus } from '../../types';

type Props = {
  route: string;
  tooltip: string;
};

const StyledNavLink = ({
  children,
  route,
  tooltip,
}: PropsWithChildren<Props>) => {
  const { resetParams } = useResetParams();
  return (
    <Tooltip label={tooltip} position="right">
      <NavLink
        data-testid={tooltip}
        className={({ isActive }) =>
          [classes.link, isActive ? classes.active : ''].join(' ')
        }
        to={`${route}?${resetParams()}`}
      >
        {children}
      </NavLink>
    </Tooltip>
  );
};

export const MainLayout = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { data: notifications } = useBusinessInvitations(
    InvitationStatus.PENDING
  );

  return (
    <AppShell
      withBorder={false}
      className={'mantine-AppShell-main'}
      navbar={{ width: 57, breakpoint: 'sm' }}
    >
      <AppShell.Navbar className={classes['nav-bar']}>
        <Stack justify="space-between" h="100%">
          <Center mt={15}>
            <Stack>
              <UnstyledButton to="/" component={Link}>
                <Center ml={5}>
                  <Logo width={30} color="white" variant="submark" />
                </Center>
              </UnstyledButton>
              <StyledNavLink route="/map/plots" tooltip={t('nav.links.plots')}>
                <Image src={PLOTS_ICON} w={23} />
              </StyledNavLink>
              {/* TODO replace SVG's with Beta or custom icons */}
              <StyledNavLink
                route="/map/suppliers"
                tooltip={t('nav.links.suppliers')}
              >
                <Image src={SUPPLIERS_ICON} w={23} />
              </StyledNavLink>
              <StyledNavLink
                route="/map/buyers"
                tooltip={t('nav.links.buyers')}
              >
                <Image src={BUYERS_ICON} w={23} />
              </StyledNavLink>
              <StyledNavLink
                route="/map/notifications"
                tooltip={t('nav.links.notifications')}
              >
                <div className={classes.notifications}>
                  {!!notifications?.length && <div className={classes.dot} />}
                  <Beta.Icon icon="bell" color="white" />
                </div>
              </StyledNavLink>
            </Stack>
          </Center>
          <Stack>
            <Divider />
            <Center>
              {/* Can not use NavLink because Profile is a dropdown not a link and does not have an active state */}
              <div
                data-testid="dropdown"
                className={[
                  classes.link,
                  location.pathname === '/admin' ? classes.active : '',
                ].join(' ')}
              >
                <Profile />
              </div>
            </Center>
          </Stack>
        </Stack>
      </AppShell.Navbar>
      <AppShell.Main>
        <Outlet />
      </AppShell.Main>
    </AppShell>
  );
};
