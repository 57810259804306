import { useTranslation } from 'react-i18next';
import { Modal, Stack, Text, Title } from '@liveeo/component-library';
import { InviteUser } from './InviteUser';

type InviteMembersProps = {
  isOpen: boolean;
  toggleOpen: () => void;
};

export const InviteMembers = ({ isOpen, toggleOpen }: InviteMembersProps) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={<Title order={4}>Invite members</Title>}
      opened={isOpen}
      onClose={() => {
        toggleOpen();
      }}
      centered
    >
      <Stack>
        {/* TODO: Add translations */}
        <Text>
          Inviting members to join your organization will grant them access to
          the same information you have, but without editing rights.
        </Text>
        <InviteUser cancel={toggleOpen} />
      </Stack>
    </Modal>
  );
};
