import { useAuth0 } from '@auth0/auth0-react';
import * as Sentry from '@sentry/react';

const API_URL = `${
  import.meta.env['NX_EUDR_DEFORESTATION_MONITORING_SERVICE_BASE_URL'] || ''
}`;

export const useFetchApi = () => {
  const { getAccessTokenSilently, logout } = useAuth0();

  return async (path: string, init?: Parameters<typeof fetch>['1']) => {
    const token = await getAccessTokenSilently();
    const response = await fetch(`${API_URL}/${path}`, {
      ...init,
      headers: {
        'Content-Type': 'application/json',
        ...(token ? { authorization: `Bearer ${token}` } : undefined),
        ...init?.headers,
      },
    });

    if (token && response.status === 401) {
      Sentry.captureMessage('401 Unauthorized access');
      logout();
    }

    if (!response.ok) {
      const error: any = await response.json();
      Sentry.captureException(error.message);
      throw new Error(error?.message);
    }

    if (response.status === 204) {
      return response;
    }

    return await response.json();
  };
};
