import { ZodSchema, z } from 'zod';
import * as Roles from './roles';

// from https://github.com/colinhacks/zod/discussions/672#discussioncomment-5851662
const makeFilteredArraySchema = <T extends ZodSchema>(schema: T) =>
  z
    .array(z.unknown()) // `z.unknown()` more relevant than `z.any()` and avoid linting issue with any
    .transform((items) =>
      items?.filter(
        (item): item is z.infer<T> => schema.safeParse(item).success
        // `item is z.infer<T>` prevents the output type to be `unknown`. Ok to cast the type as it's been validated
      )
    );

const jwtIdentityTokenSchema = z.object({
  sub: z.string(),
  orgId: z.string().optional(), // currently, we allow login to personal accounts in Auth0. this may change in the nearer future.
  'leo-eudr/roles': makeFilteredArraySchema(Roles.schema),
  email: z.string().email(),
  email_verified: z.boolean(),
  name: z.string().optional(),
  picture: z.string().optional(),
});
export const schema = jwtIdentityTokenSchema.transform((u) => ({
  sub: u.sub,
  orgId: u.orgId,
  roles: u['leo-eudr/roles'],
  emailVerified: u.email_verified,
  email: u.email,
  name: u.name,
  picture: u.picture,
}));
export type User = z.infer<typeof schema>;
