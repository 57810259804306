import { useAuth0 } from '@auth0/auth0-react';
import { UpdateUser, User } from '../shared/types/user';
import { useFetchApi } from './useFetchApi';
import { useNotification } from './useNotification';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';

export const useUser = () => {
  const fetch = useFetchApi();
  const queryClient = useQueryClient();
  const { apiError } = useNotification();
  const { logout } = useAuth0();

  const logoutUser = async () => {
    await logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  const { data, isLoading, isError } = useQuery({
    queryKey: ['user'],
    queryFn: () => fetch('users/me') as Promise<User>,
    onError: () => {
      apiError();
      logoutUser();
    },
  });

  const updateMutation = useMutation({
    mutationKey: ['user'],
    mutationFn: (payload: UpdateUser) =>
      fetch(`users/${data?.auth0UserId}`, {
        method: 'PATCH',
        body: JSON.stringify(payload),
      }),
  });

  const updateUser = (payload: UpdateUser) =>
    updateMutation.mutateAsync(payload, {
      onSuccess: (user) => {
        queryClient.setQueryData(['user'], user);
        queryClient.invalidateQueries({ queryKey: ['user'] });
      },
      onError: apiError,
    });

  const completeOnboarding = () =>
    updateMutation.mutate(
      { completed: true },
      {
        onSuccess: (user) => {
          queryClient.setQueryData(['user'], user);
          queryClient.invalidateQueries({ queryKey: ['user'] });
        },
        onError: apiError,
      }
    );

  return { data, isLoading, isError, updateUser, completeOnboarding };
};
