import { User, Plot, Request, Statuses } from './index';

export enum InvitationStatus {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  EXPIRED = 'EXPIRED',
  CANCELLED = 'CANCELLED',
}

export type Business = {
  id: string;
  members: User[];
  email?: string;
  name?: string;
  username?: string;
  addressStreet?: string;
  addressArea?: string;
  addressZip?: string;
  addressPostcode?: string;
  addressCity?: string;
  addressCountry?: string;
  addressCountryCode?: string;
  addressLine1?: string;
  addressLine2?: string;
  initialLocale: string;
  units?: string;
  plots: Plot[];
  inboundRequests: Request[];
  outboundRequests: Request[];
  auth0OrganizationId?: string;
  firstName: string;
  lastName: string;
  status: Statuses;
  contactPerson?: User;
  machineToMachineClientId?: string;
};

export type BusinessInvitation = {
  id: string;
  sentByUser: {
    auth0UserId: string;
    locale: string;
    updatedAt: string;
    createdAt: string;
    completed: boolean;
    firstName: string;
    lastName: string;
    businessId: string;
    email: string;
    business: Business;
  };
  email: string;
  status: InvitationStatus;
  createdAt: string;
  updatedAt: string;
};

export type InvitationPayload = {
  id: string;
  update: {
    status: InvitationStatus;
  };
};

export type BusinessConnection = {
  id: string;
  sourceBusiness: Business;
  targetBusiness: Business;
  plotsCount: number;
  createdAt: string;
  updatedAt: string;
  metadata: null;
};
