import { PropsWithChildren } from 'react';
import classes from './Subrow.module.css';

type Props = {
  colSpan: number;
};

export const Subrow = ({ children, colSpan }: PropsWithChildren<Props>) => {
  return (
    <td colSpan={colSpan} className={classes.root}>
      {children}
    </td>
  );
};
