import {
  Title,
  Text,
  SimpleGrid,
  Stack,
  Image,
  Center,
} from '@liveeo/component-library';
import { SidePanelContainer } from '../Map/SidePanelContainer';
import { useParams } from 'react-router-dom';
import { useResetParams } from '../../hooks/useRestParams';
import { PlotsTable } from '../Plots/PlotsTable';
import { useTranslation } from 'react-i18next';
import { useToggleAside } from '../../hooks/useToggleAside';
import { Table } from '../../shared/components/Table';
import { useSupplierPlots } from '../../hooks/useSupplierPlots';
import { useSuppliers } from '../../hooks/useSuppliers';
import CLOCK_ICON from '../../../assets/clock.svg';
import classes from './SupplierDetailPanel.module.css';
import { useTracking } from '../../hooks';
import { useEffect } from 'react';

const EmptyTable = () => {
  const { t } = useTranslation();
  const { toggleAside } = useToggleAside();

  return (
    <Table.EmptyState>
      <Stack justify="center" align="center" gap="md" w={200}>
        <Image src={CLOCK_ICON} w={25} />
        <Title ta="center" order={4}>
          {t('supplier.table.empty')}
        </Title>
      </Stack>
      <Table.EmptyState.Footer handleClose={() => toggleAside(undefined)} />
    </Table.EmptyState>
  );
};

export const SupplierDetailPanel = () => {
  const { t } = useTranslation();
  const { isFetching, findSupplierById } = useSuppliers();
  const { trackEvent } = useTracking();
  const { resetParams } = useResetParams();
  const { supplierId } = useParams();
  const supplier = findSupplierById(supplierId || '');
  const { data: plots, isLoading } = useSupplierPlots(supplierId || '');
  const isEmpty = !isLoading && !plots?.length;
  // 250px (info section height) + 40px (info section margin) + 20px (header) 58px (footer height)
  const height = window.innerHeight - (250 + 40 + 20 + 58);

  useEffect(() => {
    trackEvent('Supplier', {
      step: 'supplier-details',
      action: 'open',
    });
  }, [trackEvent]);

  return supplier ? (
    <SidePanelContainer>
      <SidePanelContainer.Header>
        <SidePanelContainer.TitleWithNav
          path={`../suppliers?${resetParams()}&sel=${supplierId}`}
          name={supplier.name || '--'}
        />
      </SidePanelContainer.Header>
      <div className={classes.info}>
        <SimpleGrid className={classes.grid} cols={2}>
          <Stack gap={0}>
            <Title order={4}>{t('supplier.details.contact')}</Title>
            <Text size="lg">{`${supplier.contactPerson?.firstName || '--'} ${
              supplier.contactPerson?.lastName || '--'
            }`}</Text>
          </Stack>
          <Stack gap={0}>
            <Title order={4}>{t('supplier.details.email')}</Title>
            <Text size="lg">{supplier.contactPerson?.email || '--'}</Text>
          </Stack>
          <Stack gap={0}>
            <Title order={4}>{t('supplier.details.address')}</Title>
            <Text size="lg">{supplier.addressLine1 || '--'}</Text>
            <Text size="lg">{supplier.addressLine2 || '--'}</Text>
            <Text size="lg">{supplier.addressCity || '--'}</Text>
            <Text size="lg">{supplier.addressCountry || '--'}</Text>
          </Stack>
        </SimpleGrid>
      </div>
      {isLoading ? (
        <Table.Loader height={height} />
      ) : isEmpty ? (
        <EmptyTable />
      ) : (
        <PlotsTable plots={plots} height={height} isSupplierView={true} />
      )}
    </SidePanelContainer>
  ) : !isFetching && !supplier ? ( // TODO replace with a designed error view
    <SidePanelContainer>
      <Center h={height}>
        <Stack align="center" gap={5}>
          <Title order={4}>{t('error.title')}</Title>
          <Text>{t('error.data')}</Text>
        </Stack>
      </Center>
    </SidePanelContainer>
  ) : (
    <Table.Loader height={height} />
  );
};
