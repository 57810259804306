import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from 'react';
import { EnvFlag, Environments, FlagKey } from '../shared/types/feature-flags';
import { featureFlags } from '../config/feature-flags';
import { useAuth0 } from '@auth0/auth0-react';

type FlagsValue = Partial<Record<FlagKey, boolean>>;

const FlagsContext = createContext<FlagsValue>({});

const nameSpacedFeatureFlags = (featureFlag: EnvFlag) => {
  const envFlag = featureFlag[import.meta.env.NX_ENV as Environments];
  if (!envFlag) return false;
  return true;
};

const FlagsProvider = ({ children }: PropsWithChildren) => {
  const { isAuthenticated, isLoading, error } = useAuth0();
  const [flags, setFlags] = useState<FlagsValue>({});

  useEffect(() => {
    if (!isLoading && !error && isAuthenticated) {
      setFlags((oldFlags) => {
        const newFlags = { ...oldFlags };
        for (const [key, config] of Object.entries(featureFlags)) {
          newFlags[key as FlagKey] = nameSpacedFeatureFlags(config);
        }
        return newFlags;
      });
    }
  }, [isLoading, error, isAuthenticated]);

  return (
    <FlagsContext.Provider value={flags}>{children}</FlagsContext.Provider>
  );
};

const useFlags = () => {
  const context = useContext(FlagsContext);
  return context;
};

export { FlagsProvider, useFlags };
