import { TextInput as MantineTextInput, TextInputProps } from '@mantine/core';
import { withRef } from '../../helpers/typescript';
import baseInputClasses from './baseInput.module.css';

const _TextInput = (props: TextInputProps, ref: any) => {
  return (
    <MantineTextInput
      classNames={baseInputClasses}
      {...props}
      radius="xs"
      ref={ref}
    />
  );
};

export const TextInput = withRef<
  HTMLInputElement,
  TextInputProps,
  typeof MantineTextInput
>({
  component: _TextInput,
});
