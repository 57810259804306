import { Geometry, bbox, area, convertArea } from '@turf/turf';

export const getBbox = (geometry: Geometry) => {
  const _bbox = bbox({
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        geometry,
      },
    ],
  });

  if (_bbox?.length) {
    return [
      [_bbox[0], _bbox[1]],
      [_bbox[2], _bbox[3]],
    ];
  }
  return [];
};

export const isPlotTooLargeToProcess = (geometry: Geometry) => {
  const _area = area(geometry);
  if (convertArea(_area, 'meters', 'hectares') > 10000) return true;
  return false;
};
