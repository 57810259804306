import { ErrorBoundary } from 'react-error-boundary';
import { ErrorComponent } from './ErrorComponent';

/*
  Use this error boundary to wrap components so that
  if an error is caught to doesn't completely render the UI useless.

  It should be used sparingly for sections where an error can be contained 
  and will not have an effect on the other components in the UI.
  https://aweary.dev/fault-tolerance-react/
*/
type Props = { title?: string };

export const GenericErrorBoundary = ({
  title,
  children,
}: React.PropsWithChildren<Props>) => {
  return (
    <ErrorBoundary fallback={<ErrorComponent title={title} />}>
      {children}
    </ErrorBoundary>
  );
};
