import type {} from '@mantine/utils';
import { Button as MantineButton, ButtonProps } from '@mantine/core';
import { ButtonHTMLAttributes } from 'react';
import { withRef } from '../../helpers/typescript';
import classes from './Button.module.css';

type Props = ButtonProps & {
  type: ButtonHTMLAttributes<HTMLButtonElement>['type'];
};

const _Button = ({ type = 'button', ...props }: Props, ref: any) => {
  return (
    <MantineButton
      {...props}
      type={type}
      ref={ref}
      classNames={classes}
      data-variant={props.variant}
    />
  );
};

export const Button = withRef<HTMLButtonElement, Props, typeof MantineButton>({
  component: _Button,
});

export const ButtonGroup = MantineButton.Group;
