import { PropsWithChildren } from 'react';
import { useTableContext } from './TableContext';
import classes from './Header.module.css';

type Props = {
  onClick?: (() => void) | any;
};

export const Header = ({ children }: PropsWithChildren) => {
  return <thead>{children}</thead>;
};

export const HeaderRow = ({ children, ...props }: PropsWithChildren) => (
  <tr {...props}>{children}</tr>
);

export const HeaderCell = ({ children, onClick }: PropsWithChildren<Props>) => {
  return (
    <th className={classes['cell']} onClick={onClick}>
      <div className={classes['cellContent']}>{children}</div>
    </th>
  );
};

export const HeaderTitle = ({ children, ...props }: PropsWithChildren) => (
  <p {...props} className={classes['title']}>
    {children}
  </p>
);
