import React, { useContext } from 'react';

export type TableContextProps = {
  scrolled: boolean;
};

export const TableContext = React.createContext<TableContextProps>({
  scrolled: false,
});

export const useTableContext = (): TableContextProps => {
  const context = useContext(TableContext);
  return context;
};
