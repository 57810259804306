import { PropsWithChildren } from 'react';
import { Flex, Text, Center, Title } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';

type Props = { title?: string; color?: string };

const ErrorComponent = ({ title, color }: Props) => {
  const { t } = useTranslation();
  return (
    <Center>
      <Flex<PropsWithChildren<any>>
        p="md"
        m="md"
        direction="column"
        wrap="wrap"
      >
        <Title mx="md" mb="sm" order={5}>
          {title || t<string>('error.data')}
        </Title>

        <Text mx="md" ta="justify">
          {t<string>('error.message')}
        </Text>
      </Flex>
    </Center>
  );
};

export { ErrorComponent };
