import { useTranslation } from 'react-i18next';
import {
  BackgroundImage,
  Center,
  Text,
  Beta,
  Group,
  Stack,
  Button,
  Anchor,
} from '@liveeo/component-library';
import classes from './error.module.css';
import { useAuth0 } from '@auth0/auth0-react';

export const UnverifiedEmailError = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  return (
    <BackgroundImage
      src="https://live-eo-statics.netlify.app/login-background.webp"
      h="100vh"
      w="100%"
    >
      <Center h="100%">
        <Stack p="xl" classNames={{ root: classes.unverifiedContainer }}>
          <Group>
            <Text size="xl">{t<string>('confirmEmail.heading')}</Text>
            <Beta.Icon icon="envelope" />
          </Group>
          <Text>{t<string>('confirmEmail.body')}</Text>
          <Text>{t<string>('confirmEmail.body2')}</Text>
          <Text size="lg">
            {t<string>('confirmEmail.trouble')}{' '}
            <span>
              <Anchor
                href={import.meta.env['NX_SUPPORT_EMAIL']}
                target="_blank"
              >
                {t('header.support')}
              </Anchor>
            </span>
          </Text>
          <Group justify="flex-end">
            <Button onClick={() => logout()}>
              {t<string>('error.backToLogin')}
            </Button>
          </Group>
        </Stack>
      </Center>
    </BackgroundImage>
  );
};
