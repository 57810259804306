import { ReactNode } from 'react';
import { Button, Group, Modal, Stack } from '../';
import { useTranslation } from 'react-i18next';
import { GenericErrorMessage } from './GenericErrorMessage';

type Props = {
  children: ReactNode;
  isOpen: boolean;
  cancel: () => void;
  confirm: () => void;
  type?: 'delete' | 'update';
  title?: string;
  translation?: {
    cancel?: string;
    delete?: string;
    update?: string;
  };
};

export const ConfirmationModal = ({
  isOpen,
  children,
  cancel,
  confirm,
  type = 'delete',
  translation = {},
  title = '',
}: Props) => {
  const {
    cancel: cancelString = 'cancel',
    delete: deleteString = 'delete',
    update: updateString = 'update',
  } = translation;
  const { t } = useTranslation();
  return (
    <GenericErrorMessage>
      <Modal opened={isOpen} onClose={cancel} title={title} centered>
        <Stack gap={30} align="center">
          {children}
          <Group justify="flex-end" w="100%">
            <Button variant="outline" onClick={cancel}>
              {t<string>(cancelString)}
            </Button>
            <Button
              onClick={confirm}
              color={type === 'delete' ? 'red' : 'primary'}
            >
              {type === 'delete'
                ? t<string>(deleteString)
                : t<string>(updateString)}
            </Button>
          </Group>
        </Stack>
      </Modal>
    </GenericErrorMessage>
  );
};
