import { Routes, Route, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { CreatePlotForm } from './CreatePlotForm';
import { UpdatePlotForm } from './UpdatePlotForm';
import { usePlot, usePlots } from '../../hooks';
import { GenericErrorFallback } from '../../shared/components/GenericErrorFallback';
import { DeletePlotModal } from '../Plots/DeletePlotModal';

type Props = {
  draw: any;
  features: any;
  onDeletePlot: () => void;
};

export const PlotRoutes = ({ draw, features, onDeletePlot }: Props) => {
  const { plotId } = useParams();
  const { createPlot } = usePlots();
  const { data: plot, updatePlot } = usePlot(plotId);

  return (
    <Routes>
      <Route index element={null} />
      <Route
        path="plots/draw-plot"
        element={
          isEmpty(features) ? null : (
            <CreatePlotForm
              opened={true}
              features={features}
              onDeletePlot={onDeletePlot}
              createPlot={createPlot}
            />
          )
        }
        errorElement={<GenericErrorFallback />}
      />
      <Route
        path="plots/edit-plot/:plotId"
        element={
          plotId && plot ? (
            <UpdatePlotForm
              draw={draw.current}
              features={features}
              plot={plot}
              onDeletePlot={onDeletePlot}
              updatePlot={updatePlot}
            />
          ) : null
        }
        errorElement={<GenericErrorFallback />}
      />
      <Route
        path="plots/delete-plot/:plotId"
        element={
          plotId && plot ? (
            <DeletePlotModal name={plot.name} plotId={plotId} />
          ) : null
        }
        errorElement={<GenericErrorFallback />}
      />
    </Routes>
  );
};
