import { PropsWithChildren } from 'react';
import { defaultTheme } from '../../styles/theme';
import classes from './Body.module.css';

type RowProps = PropsWithChildren<{
  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isSelected?: boolean;
  darkMode?: boolean;
}>;

export const Body = ({ children, ...props }: PropsWithChildren) => (
  <tbody {...props} className={classes['body']}>
    {children}
  </tbody>
);

export const Row = ({
  children,
  onClick,
  onMouseEnter,
  onMouseLeave,
  isSelected,
  darkMode = false,
}: RowProps) => {
  return (
    <tr
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{
        cursor: onClick ? 'pointer' : 'default',
        background: isSelected
          ? defaultTheme.colors.secondary200
          : defaultTheme.colors.pureWhite,
        ...(darkMode && {
          background: isSelected ? '#625362' : '#1f1d22',
          color: '#dee2e6',
        }),
      }}
    >
      {children}
    </tr>
  );
};

export const Cell = ({ children }: PropsWithChildren) => (
  <td className={classes['cell']}>
    <div className={classes['cellContent']}>{children}</div>
  </td>
);
