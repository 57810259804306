import { useMemo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as User from '../schema/user';
import { useEffect } from 'react';
import * as Sentry from '@sentry/react';

export const useAuth0User = () => {
  const { isAuthenticated, user: auth0User } = useAuth0();
  const validatedUser = useMemo(() => {
    const validationResult = User.schema.safeParse(auth0User);
    if (validationResult.success) {
      return validationResult.data;
    }
    return undefined;
  }, [auth0User]);

  useEffect(() => {
    if (auth0User) {
      Sentry.setUser({
        email: auth0User.email,
      });
    }
  }, [auth0User, Sentry]);

  return { isAuthenticated, auth0User: validatedUser };
};
