import { useAuth0 } from '@auth0/auth0-react';
import { Auth0LoginRedirect } from './Auth0LoginRedirect';
import { Router } from './Router';
import * as Sentry from '@sentry/react';

const App = () => {
  const { isAuthenticated } = useAuth0();

  return isAuthenticated ? <Router /> : <Auth0LoginRedirect />;
};

export default Sentry.withProfiler(App);
