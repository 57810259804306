import {
  NumberInput as MantineNumberInput,
  NumberInputProps,
} from '@mantine/core';
import { withRef } from '../../helpers/typescript';
import baseInputClasses from './baseInput.module.css';

export const _NumberInput = (props: NumberInputProps, ref: any) => {
  return (
    <MantineNumberInput
      classNames={baseInputClasses}
      {...props}
      ref={ref}
      radius="xs"
    />
  );
};

export const NumberInput = withRef<
  HTMLInputElement,
  NumberInputProps,
  typeof MantineNumberInput
>({
  component: _NumberInput,
});
