import {
  Box,
  PasswordInput as MantinePasswordInput,
  PasswordInputProps,
  Popover,
  Progress,
  Text,
} from '@mantine/core';
import { ForwardedRef, useState } from 'react';
import { withRef } from '../../helpers/typescript';
import { Icon } from '../Icon';
import baseInputClasses from './baseInput.module.css';
import classes from './PasswordInput.module.css';

const _PasswordInput = (props: PasswordInputProps, ref: any) => {
  return (
    <MantinePasswordInput
      classNames={{ ...baseInputClasses, ...classes }}
      {...props}
      radius="xs"
      ref={ref}
    />
  );
};

export const PasswordInput = withRef<
  HTMLInputElement,
  PasswordInputProps,
  typeof MantinePasswordInput
>({
  component: _PasswordInput,
});

type PasswordRequirements = { re: RegExp; label: string }[];

type StrongPasswordInputProps = {
  requirements: PasswordRequirements;
} & PasswordInputProps;

export const _StrongPasswordInput = (
  { requirements, onChange, ...props }: StrongPasswordInputProps,
  ref: ForwardedRef<HTMLInputElement>
) => {
  const [popoverOpened, setPopoverOpened] = useState(false);
  const [value, setValue] = useState('');
  const checks = requirements.map((requirement, index) => (
    <PasswordRequirement
      key={index}
      label={requirement.label}
      meets={requirement.re.test(value)}
    />
  ));

  const strength = getStrength(value, requirements);
  const color = strength === 100 ? 'green' : strength > 50 ? 'orange' : 'red';

  return (
    <Popover
      opened={popoverOpened}
      position="bottom"
      width="target"
      transitionProps={{ transition: 'pop' }}
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setPopoverOpened(true)}
          onBlurCapture={() => setPopoverOpened(false)}
        >
          <PasswordInput
            {...props}
            ref={ref}
            onChange={(event) => {
              setValue(event.currentTarget.value);
              if (onChange) onChange(event);
            }}
          />
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Progress
          color={color}
          value={strength}
          size={5}
          style={{ marginBottom: 10 }}
        />
        {checks}
      </Popover.Dropdown>
    </Popover>
  );
};

function PasswordRequirement({
  meets,
  label,
}: {
  meets: boolean;
  label: string;
}) {
  return (
    <Text
      c={meets ? 'green' : 'red'}
      style={{ display: 'flex', alignItems: 'center' }}
      mt={7}
      size="sm"
    >
      {meets ? <Icon icon="check" /> : <Icon icon="xmark" />}{' '}
      <Box ml={10}>{label}</Box>
    </Text>
  );
}

function getStrength(password: string, requirements: PasswordRequirements) {
  let multiplier = password.length > 5 ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 10);
}

export const StrongPasswordInput = withRef<
  HTMLInputElement,
  StrongPasswordInputProps,
  typeof PasswordInput
>({
  component: _StrongPasswordInput,
});
