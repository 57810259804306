import { CustomSVGProps } from './CustomSVG.interface';

export const SideBySide: React.FC<CustomSVGProps> = ({
  color = 'none',
  style,
  ...props
}) => {
  return (
    <svg
      width={style?.fontSize}
      height={style?.fontSize}
      style={style}
      viewBox="0 0 24 24"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask id="path-1-inside-1_8191_2815" fill="white">
        <rect y="4" width="24" height="16" rx="1" />
      </mask>
      <rect
        y="4"
        width="24"
        height="16"
        rx="1"
        stroke={color}
        fill="none"
        stroke-width="3"
        mask="url(#path-1-inside-1_8191_2815)"
      />
      <rect
        x="13.25"
        y="2.25"
        width="2"
        height="19.5"
        stroke="none"
        stroke-width="0.5"
      />
    </svg>
  );
};
