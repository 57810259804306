import { Paper as MantinePaper, PaperProps } from '@mantine/core';
import { withRef } from '../../helpers/typescript';

const _Paper = (props: PaperProps, ref: any) => {
  return (
    <MantinePaper
      ref={ref}
      {...props}
      shadow={props.shadow || 'md'}
      radius="xs"
    />
  );
};

export const Paper = withRef<HTMLDivElement, PaperProps, typeof MantinePaper>({
  component: _Paper,
});
