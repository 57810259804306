import { PropsWithChildren } from 'react';
import classes from './Body.module.css';

type Props = PropsWithChildren<{
  onClick?: () => void;
  isSelected?: boolean;
  isHovered?: boolean;
  onMouseOver?: () => void;
  onMouseOut?: () => void;
}>;

export const Body = ({ children, ...props }: PropsWithChildren) => (
  <tbody {...props}>{children}</tbody>
);

export const Row = ({
  children,
  onClick,
  onMouseOver,
  onMouseOut,
  isSelected = false,
  isHovered = false,
}: Props) => {
  return (
    <tr
      onClick={onClick}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      className={classes.row}
      data-selected={isSelected}
      data-hovered={isHovered}
    >
      {children}
    </tr>
  );
};

export const Cell = ({ children }: PropsWithChildren) => (
  <td className={classes['cell']}>
    <div className={classes['cellContent']}>{children}</div>
  </td>
);
