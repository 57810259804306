import { useQueryParams, BooleanParam } from 'use-query-params';

export const useToggleAside = () => {
  const [query, setQuery] = useQueryParams({
    pan: typeof BooleanParam, // side panel state
  });

  return {
    isAsideOpen: query.pan,
    toggleAside: (v: any) => {
      setQuery({ pan: v });
    },
  };
};
