import Joi from 'joi';
import { useTranslation } from 'react-i18next';
import { useForm, useFieldArray } from 'react-hook-form';
import { joiResolver } from '@hookform/resolvers/joi';
import {
  TextInput,
  Stack,
  Flex,
  ActionIcon,
  Icon,
  Button,
} from '@liveeo/component-library';
import classes from './InviteBuyersForm.module.css';
import { useTracking } from '../../hooks';
import { useEffect } from 'react';

type Props = {
  cancel: () => void;
};

const InviteSchema = Joi.object().keys({
  buyers: Joi.array().items(
    Joi.object().keys({
      email: Joi.string()
        .email({ tlds: { allow: false } })
        .required(),
    })
  ),
});

const defaultValues = {
  email: '',
};

export const InviteBuyersForm = ({ cancel }: Props) => {
  const { t } = useTranslation();
  const { trackEvent } = useTracking();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      buyers: [{ ...defaultValues }],
    },
    resolver: joiResolver(InviteSchema),
  });

  useEffect(() => {
    trackEvent('Buyer', {
      step: 'buyer-invite',
      action: 'open',
    });
  }, [trackEvent]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'buyers',
  });

  const onSubmit = (data: any) => {
    console.log(data);
    reset();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <Stack>
        {fields.map((field, index) => (
          <Flex
            key={field.id}
            justify="space-between"
            align="center"
            direction="row"
          >
            <TextInput
              {...register(`buyers.${index}.email`)}
              error={errors?.buyers?.[index]?.email?.message}
              w="90%"
              classNames={{ input: classes.input }}
            />
            {fields.length > 1 ? (
              <ActionIcon size="md" onClick={() => remove(index)}>
                <Icon icon="trash" />
              </ActionIcon>
            ) : (
              <ActionIcon
                size="md"
                onClick={() => append({ ...defaultValues })}
              >
                <Icon icon="plus" />
              </ActionIcon>
            )}
          </Flex>
        ))}
        {fields.length > 1 && (
          <Flex w="100%" justify="flex-end">
            <ActionIcon size="md" onClick={() => append({ ...defaultValues })}>
              <Icon icon="plus" />
            </ActionIcon>
          </Flex>
        )}
      </Stack>
      <Flex justify="end" gap="sm" mt="lg">
        <Button
          variant="outline"
          size="sm"
          onClick={cancel}
          className={classes.button}
        >
          {t('common.cancel')}
        </Button>
        <Button size="sm" type="submit">
          {t('common.invite')}
        </Button>
      </Flex>
    </form>
  );
};
