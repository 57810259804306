import { useQueryParams, createEnumParam, withDefault } from 'use-query-params';
import { InvitationStatus } from '../shared/types';

const inviteStatus = Object.values(InvitationStatus);
export type InviteStatus = (typeof inviteStatus)[number];
export const InviteParam = withDefault(
  createEnumParam<InviteStatus>([...inviteStatus]),
  InvitationStatus.PENDING
);

export const useInvitationStatus = () => {
  const [query, setQuery] = useQueryParams({
    status: InviteParam,
  });

  return {
    status: query.status,
    setStatus: (status: InviteStatus | undefined) => setQuery({ status }),
  };
};
