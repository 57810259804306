import React from 'react';
import {
  Pagination as MantinePagination,
  PaginationProps,
} from '@mantine/core';
import classes from './Pagination.module.css';

export const Pagination = (props: PaginationProps) => {
  return (
    <MantinePagination
      size={props.size || 'sm'}
      radius="xs"
      classNames={classes}
      {...props}
    />
  );
};
