import { Button, Center, Stack, Text, Title } from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const GenericErrorFallback = () => {
  const { t } = useTranslation();
  return (
    <Center mt={200} bg="dark.7">
      <Stack gap="xl">
        <Title order={1}>{t('error.title')}</Title>
        <Text>{t('error.generic')}</Text>
        <Button w={150} to="/" component={Link}>
          {t('error.button')}
        </Button>
      </Stack>
    </Center>
  );
};
