import { useTranslation } from 'react-i18next';
import { Stepper, Text } from '@liveeo/component-library';

type Props = {
  active: number;
};

export const Progress = ({ active }: Props) => {
  const { t } = useTranslation();
  return (
    <Stepper size="sm" active={active} orientation="vertical">
      <Stepper.Step
        label={
          <Text c="white">{t('profile.onboarding.steps.account.title')}</Text>
        }
        allowStepClick={true}
      />
      <Stepper.Step
        label={
          <Text c="white">{t('profile.onboarding.steps.import.title')}</Text>
        }
        allowStepClick={true}
      />
    </Stepper>
  );
};
