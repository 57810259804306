import { useToggle } from '@liveeo/helpers';
import { useTranslation } from 'react-i18next';
import { Beta, Flex, Stack, Text } from '@liveeo/component-library';
import { useM2MClientSecret } from '../../../hooks';
import CopyToClipboard from 'react-copy-to-clipboard';
import classes from '../Admin.module.css';

type ShowClientProps = {
  clientId: string | undefined;
};

export const ShowClientSecret = ({ clientId }: ShowClientProps) => {
  const { clientSecret } = useM2MClientSecret(clientId);
  const [showSecret, setShowSecret] = useToggle();
  const [copiedSecret, setCopiedSecret] = useToggle();
  const { t } = useTranslation();

  return (
    <Stack gap="xs" mt="md" mb="md">
      <Text size="md">{t<string>('developerTools.secret')}</Text>
      <Flex
        classNames={{ root: classes['mantine-Flex-root'] }}
        p="xs"
        justify="space-between"
        align="center"
      >
        {showSecret ? (
          clientSecret?.clientSecret || t<string>('developerTools.noClient')
        ) : (
          <Text size="xl">{clientSecret?.clientSecret.replace(/./g, '.')}</Text>
        )}

        <Flex justify="space-between" w="65px">
          <Beta.Icon icon="eye" onClick={() => setShowSecret()} />
          {clientSecret?.clientSecret && (
            <CopyToClipboard
              text={clientSecret.clientSecret}
              onCopy={() => {
                setCopiedSecret();
              }}
              data-testid="m2mClientSecretValueCopyButton"
            >
              {!copiedSecret ? (
                <Beta.Icon icon="copy" />
              ) : (
                <Beta.Icon icon="check" />
              )}
            </CopyToClipboard>
          )}
        </Flex>
      </Flex>
    </Stack>
  );
};
