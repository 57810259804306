import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Flex,
  Title,
  Text,
  Stack,
  Divider,
  LoadingSpinner,
  Group,
  Image,
  Button,
} from '@liveeo/component-library';
import { GenericErrorBoundary } from '../../shared/components/GenericErrorBoundary';
import { useUser, useAuth0User } from '../../hooks';
import classes from './Admin.module.css';
import { Link } from 'react-router-dom';

export const UserProfile = () => {
  const { data: user, isLoading } = useUser();
  const { auth0User } = useAuth0User();
  const { t } = useTranslation();

  let fullName = '';
  let email = '';
  if (user && auth0User) {
    fullName = `${user.firstName} ${user.lastName}`;
    email = auth0User.email;
  }

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <GenericErrorBoundary title={`${t('error.profile')}`}>
      <Flex<PropsWithChildren<any>>
        direction="column"
        gap="md"
        c="white"
        p="md"
        className={classes.profileContainer}
      >
        <Title order={1}>LiveEO</Title>
        <Text>{t<string>('profile.title')}</Text>
        <Stack>
          <Flex<PropsWithChildren<any>> align="center" gap="md">
            <Image src="/profile.svg" />
            <Text>{fullName}</Text>
          </Flex>
          <Flex<PropsWithChildren<any>> align="center" gap="md">
            <Image src="/mail.svg" height={15} />
            <Text>{email}</Text>
          </Flex>
        </Stack>
        <Divider />
        <Group justify="space-between">
          <Button variant="outline" component={Link} to="dev-tools" w="100%">
            {t<string>('devTools.title')}
          </Button>
        </Group>
      </Flex>
    </GenericErrorBoundary>
  );
};
