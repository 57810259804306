import { Container, useMantineTheme } from '@mantine/core';

export interface LogoProps extends React.ComponentPropsWithoutRef<'div'> {
  variant?: 'primary' | 'submark';
  color?: 'white' | 'black';
  width?: number;
}

export const Logo = ({
  variant = 'primary',
  width = 128,
  color = 'black',
  ...props
}: LogoProps) => {
  const theme = useMantineTheme();
  const _color = color === 'black' ? theme.black : theme.white;
  const green = theme.colors.green[2];
  switch (variant) {
    case 'primary':
      return (
        <Container size={width} px={0} m={0} lh={0}>
          <Primary color={_color} green={green} />
        </Container>
      );
    case 'submark':
      return (
        <Container size={width} px={0} m={0} lh={0} {...props}>
          <Submark color={_color} green={green} />
        </Container>
      );
    default:
      return (
        <Container size={width} px={0} m={0} lh={0} {...props}>
          <Primary color={_color} green={green} />
        </Container>
      );
  }
};

const Primary = ({ color, green }: { color: string; green: string }) => (
  <svg viewBox="0 0 156 40" xmlns="http://www.w3.org/2000/svg" width="100%">
    <path
      d="M149.592 19.7315C149.592 19.7477 149.593 19.7638 149.593 19.7808C149.593 21.66 149.265 23.45 148.613 25.15C147.959 26.8515 147.014 28.3746 145.776 29.7184C144.538 31.0631 143.034 32.1238 141.263 32.9015C139.491 33.6784 137.506 34.0677 135.306 34.0677C133.104 34.0677 131.119 33.6784 129.348 32.9015C127.577 32.1238 126.073 31.0631 124.835 29.7184C123.597 28.3746 122.651 26.8515 121.999 25.15C121.345 23.45 121.019 21.66 121.019 19.7808C121.019 17.8054 121.345 15.9584 121.999 14.2408C122.651 12.5238 123.597 11.0092 124.835 9.69768C126.073 8.38537 127.577 7.35768 129.348 6.61153C131.119 5.86691 133.104 5.49383 135.306 5.49383C135.389 5.49383 135.471 5.49537 135.553 5.49691V0.110756C135.526 0.110756 135.499 0.109985 135.471 0.109985C132.144 0.109985 129.259 0.671525 126.816 1.79383C124.372 2.91691 122.336 4.40537 120.707 6.25999C119.077 8.11537 117.865 10.1646 117.068 12.4108C116.271 14.6561 115.872 16.8854 115.872 19.0961V20.2192C115.872 22.2608 116.262 24.3877 117.042 26.5984C117.822 28.8115 119.009 30.8861 120.603 32.8261C122.197 34.7654 124.224 36.3308 126.686 37.5215C129.146 38.7115 132.074 39.3084 135.471 39.3084C138.867 39.3084 141.796 38.7115 144.257 37.5215C146.718 36.3308 148.745 34.7654 150.34 32.8261C151.934 30.8861 153.121 28.8115 153.901 26.5984C154.681 24.3877 155.071 22.2608 155.071 20.2192V19.7315H149.592Z"
      fill={color}
    />
    <path
      d="M144.423 0.126953H155.102V10.8054H144.423V0.126953Z"
      fill={color}
    />
    <path d="M140.397 4.30774H151.075V14.9862H140.397V4.30774Z" fill={green} />
    <path
      d="M96.3847 22.1062H112.219V17.3654H96.3847V5.39538H113.84V0.0561523H91.1055V39.2285H114.379V34.0685H96.3847V22.1062Z"
      fill={color}
    />
    <path
      d="M68.8189 15.9447C70.1197 15.0462 71.7589 14.5962 73.7366 14.5962C75.6443 14.5962 77.2235 14.9947 78.4728 15.7893C79.722 16.5854 80.6581 17.7001 81.2828 19.1347C81.6474 19.9731 81.9051 20.9039 82.0566 21.9254H65.2243C65.3835 21.1085 65.6097 20.3424 65.9043 19.6278C66.5466 18.0716 67.5181 16.8447 68.8189 15.9447ZM81.2051 12.1847C79.2443 10.8878 76.7551 10.537 73.7366 10.537C71.412 10.537 69.3912 10.647 67.6743 11.4585C65.9566 12.2716 64.5428 13.3601 63.4328 14.7262C62.322 16.0924 61.4981 17.6224 60.9605 19.3162C60.422 21.0108 60.1543 22.757 60.1543 24.5547V25.4885C60.1543 27.2516 60.422 28.9808 60.9605 30.6747C61.4981 32.3693 62.322 33.8993 63.4328 35.2647C64.5428 36.6316 65.9828 37.7285 67.752 38.5578C69.5212 39.3878 71.6205 39.6047 74.0489 39.6047C76.2351 39.6047 78.1943 39.4401 79.9297 38.7139C81.6643 37.9878 83.1043 36.9331 84.2489 35.5501C85.3943 34.167 86.1566 32.5247 86.5389 30.6231H81.8551C81.5774 31.9024 80.7535 33.0262 79.3835 33.9947C78.012 34.9624 76.2351 35.4462 74.0489 35.4462C71.9674 35.4462 70.2505 34.9885 68.8974 34.0716C67.5443 33.1562 66.5466 31.9116 65.9043 30.3378C65.3766 29.0447 65.0666 27.6162 64.9728 26.0539H87.0074V23.7247C87.0074 21.4085 86.5297 19.2301 85.5766 17.1893C84.6212 15.1501 83.1643 13.4808 81.2051 12.1847Z"
      fill={color}
    />
    <path
      d="M55.7787 10.7346L48.6172 35.1731H47.4641L39.2395 10.7346H33.8525L43.6572 39.2285H52.331L60.8425 10.7346H55.7787Z"
      fill={color}
    />
    <path d="M31.143 10.7346H25.9707V39.2285H31.143V10.7346Z" fill={color} />
    <path d="M31.143 3.01624H25.9707V8.18854H31.143V3.01624Z" fill={color} />
    <path d="M5.27923 0H0V39.2285H21.6523V34.4877H5.27923V0Z" fill={color} />
  </svg>
);

const Submark = ({ color, green }: { color: string; green: string }) => (
  <svg
    viewBox="0 0 40 41"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
  >
    <path
      d="M34.3823 20.2322C34.3823 20.2487 34.3831 20.2652 34.3831 20.2825C34.3831 22.2001 34.049 24.0267 33.3838 25.7615C32.7171 27.4978 31.7532 29.052 30.4912 30.4234C29.2292 31.7955 27.695 32.8779 25.8895 33.6715C24.0831 34.4644 22.0588 34.8615 19.8155 34.8615C17.5708 34.8615 15.5464 34.4644 13.7408 33.6715C11.9345 32.8779 10.4011 31.7955 9.13912 30.4234C7.87712 29.052 6.91238 27.4978 6.24726 25.7615C5.58057 24.0267 5.24801 22.2001 5.24801 20.2825C5.24801 18.2667 5.58057 16.382 6.24726 14.6292C6.91238 12.8772 7.87712 11.3316 9.13912 9.99322C10.4011 8.65407 11.9345 7.60537 13.7408 6.84396C15.5464 6.08412 17.5708 5.70341 19.8155 5.70341C19.9003 5.70341 19.9834 5.70498 20.0673 5.70655V0.210259C20.0399 0.210259 20.0124 0.209473 19.9842 0.209473C16.5919 0.209473 13.6499 0.782494 11.1588 1.92775C8.66695 3.07379 6.5908 4.59269 4.93036 6.48523C3.26834 8.37855 2.03222 10.4697 1.21965 12.7618C0.406288 15.0531 0 17.3279 0 19.5839V20.7299C0 22.8132 0.39766 24.9836 1.19298 27.2396C1.9883 29.4979 3.19853 31.6149 4.82368 33.5946C6.44884 35.5735 8.51635 37.1709 11.0262 38.386C13.5346 39.6003 16.5205 40.2095 19.9842 40.2095C23.447 40.2095 26.433 39.6003 28.9429 38.386C31.452 37.1709 33.5187 35.5735 35.1447 33.5946C36.7698 31.6149 37.9808 29.4979 38.7762 27.2396C39.5715 24.9836 39.9684 22.8132 39.9684 20.7299V20.2322H34.3823Z"
      fill={color}
    />
    <path d="M29.1118 0.226807H40V11.1236H29.1118V0.226807Z" fill={color} />
    <path d="M25.0063 4.49316H35.8945V15.39H25.0063V4.49316Z" fill={green} />
  </svg>
);
