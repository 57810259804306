import './i18n';
import { StrictMode, useEffect } from 'react';
import * as ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { Auth0Provider } from '@auth0/auth0-react';
import { FlagsProvider } from './app/contexts/FlagsContext';
import {
  ComponentLibraryProvider,
  Notifications,
} from '@liveeo/component-library';
import App from './app/App';
import { mantineTheme } from './app/theme/theme';
import { TrackingProvider } from './app/hooks';
import CookieBot from 'react-cookiebot';
import * as Sentry from '@sentry/react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router-dom';

import '@total-typescript/ts-reset';
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';
import '@mantine/notifications/styles.css';

if (import.meta.env.NX_GIT_COMMIT_SHA) {
  console.log('Git commit sha: ', import.meta.env.NX_GIT_COMMIT_SHA);
}

if (import.meta.env.NX_ENV !== 'dev') {
  Sentry.init({
    dsn: import.meta.env.NX_TA_SENTRY_DSN,
    environment: import.meta.env.NX_ENV,
    integrations: [
      Sentry.reactRouterV6BrowserTracingIntegration({
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes,
      }),
    ],
    tracesSampleRate: 0.1,
  });
}

const QUERY_CLIENT = new QueryClient();
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <StrictMode>
    <TrackingProvider>
      <Auth0Provider
        domain={import.meta.env['NX_EUDR_AUTH0_DOMAIN']}
        clientId={import.meta.env['NX_EUDR_AUTH0_CLIENT_ID']}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: import.meta.env['NX_EUDR_AUTH0_AUDIENCE'],
        }}
        cacheLocation="localstorage"
      >
        <ComponentLibraryProvider theme={mantineTheme} colorScheme="dark">
          <QueryClientProvider client={QUERY_CLIENT}>
            <Notifications position="top-center" />
            <FlagsProvider>
              <App />
            </FlagsProvider>
            <CookieBot domainGroupId={import.meta.env.NX_COOKIEBOT_GROUPID} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </ComponentLibraryProvider>
      </Auth0Provider>
    </TrackingProvider>
  </StrictMode>
);
