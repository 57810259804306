import { Beta, Flex, Stack, Text } from '@liveeo/component-library';
import { useToggle } from '@liveeo/helpers';
import { GenericErrorBoundary } from '../../../shared/components/GenericErrorBoundary';
import CopyToClipboard from 'react-copy-to-clipboard';
import classes from '../Admin.module.css';
import { useTranslation } from 'react-i18next';
import { useBusiness } from '../../../hooks';

type ShowClientProps = {
  businessId: string;
};

export const ShowAndCopyClientId = ({ businessId }: ShowClientProps) => {
  const [copiedId, setCopiedId] = useToggle();
  const { t } = useTranslation();
  const { data: business } = useBusiness(businessId);

  return (
    <GenericErrorBoundary title={t<string>('developerTools.error')}>
      <Stack gap="xs" mt="md">
        <Text size="md">{t<string>('developerTools.client')}</Text>
        <Flex
          classNames={{ root: classes['mantine-Flex-root'] }}
          p="xs"
          justify="space-between"
          align="center"
        >
          <Text size="md" fw="lighter" data-testid="m2mClientIdValue">
            {business?.machineToMachineClientId
              ? business?.machineToMachineClientId
              : t<string>('developerTools.noClient')}
          </Text>
          <CopyToClipboard
            text={business?.machineToMachineClientId || ''}
            onCopy={() => {
              setCopiedId();
            }}
          >
            {!copiedId ? <Beta.Icon icon="copy" /> : <Beta.Icon icon="check" />}
          </CopyToClipboard>
        </Flex>
      </Stack>
    </GenericErrorBoundary>
  );
};
