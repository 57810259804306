import { convertArea, convertLength } from '@turf/helpers';

export const roundNum = (num: number, decimals: number) => {
  return Math.round(num * Math.pow(10, decimals)) / Math.pow(10, decimals);
};

export const convertUnit = (unit: number) => {
  return convertLength(unit, 'kilometers', 'miles').toFixed(3);
};

export const convertSqUnit = (unit: number) => {
  const metricVal = unit / 1000000;
  const imperialVal = metricVal / 2.58999;

  return {
    metricVal: metricVal,
    imperialVal: imperialVal,
  };
};

export const hectares = (value: number) => {
  return value / 100;
};

export const convertDecimal = (num: number) => {
  if (num % 1 !== 0) {
    return num.toFixed(2);
  } else {
    return num;
  }
};

export const squareMetersToHectares = (value: number, precision: number) => {
  const result = convertArea(value, 'meters', 'hectares');
  const rounded = roundNum(result, precision);
  return rounded.toFixed(precision);
};

export const cubicMetersToCubicFeet = (cubicMeters: number) => {
  return cubicMeters * 35.3147;
};
