import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { LoadingSpinner } from '../LoadingSpinner';
import { Body, Cell, Row } from './Body';

import {
  Header,
  HeaderCell,
  HeaderRow,
  HeaderTitle,
  SortButton,
} from './Header';

import { ScrollArea } from '@mantine/core';
import { TableContext } from './TableContext';
import classes from './Table.module.css';

export type NamedComponent<P> = React.FunctionComponent<P> & {
  componentName?: string;
};

const EmptyState: NamedComponent<{ text: string }> = ({ text }) => (
  <div className={classes['emptyState']}>{text}</div>
);

const LoadingState: NamedComponent<unknown> = () => (
  <div className={classes['loadingState']}>
    <div>
      <LoadingSpinner />
    </div>
  </div>
);

type Props = PropsWithChildren<{
  className?: string;
  customFooter?: JSX.Element | undefined;
  height?: number | string;
  tableElementOverrides?: string;
}>;
/**
 *     A set of modular/compound components to build data tables.
 *     This component also exports a set of utilities to help
 *     you add more complex functionality to your data tables.
 *     To see which utilities are available see:
 *     https://tanstack.com/table/v8
 *     We recommend using these Tanstack headless utilities
 *     if you need dynamic data tables.
 */
export const Table = ({
  children,
  height = 'auto',
  customFooter,
  className,
}: Props) => {
  const [scrolled, setScrolled] = useState(false);
  const childrenArray = React.Children.toArray(children);
  const elemArray = childrenArray as ReactElement[];
  const emptyStateChild = elemArray.find(
    ({ type }) =>
      typeof type !== 'string' && type.name === EmptyState.componentName
  );
  const loadingStateChild = elemArray.find(
    ({ type }) =>
      typeof type !== 'string' && type.name === LoadingState.componentName
  );
  const tableChildren = elemArray.filter(({ type }) =>
    [EmptyState, LoadingState].every(
      (c) => typeof type !== 'string' && type.name !== c.componentName
    )
  );

  return (
    <TableContext.Provider value={{ scrolled }}>
      <ScrollArea
        style={{ height }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        pos="relative"
      >
        <table className={`${classes['table']} ${className}`}>
          {tableChildren}
        </table>
        {emptyStateChild}
        {loadingStateChild}
        <div className={classes['customFooter']}>{customFooter}</div>
      </ScrollArea>
    </TableContext.Provider>
  );
};

Table.Header = Header;
(Table.Header as NamedComponent<unknown>).componentName = 'Header';
Table.HeaderRow = HeaderRow;
Table.HeaderCell = HeaderCell;
Table.HeaderTitle = HeaderTitle;
Table.HeaderSortButton = SortButton;

Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;
Table.EmptyState = EmptyState;
Table.EmptyState.componentName = 'EmptyState';
Table.LoadingState = LoadingState;
Table.LoadingState.componentName = 'LoadingState';
