export const invitations = [
  {
    name: 'Kool Herc',
    email: 'koolherc@example.com',
    status: 'pending',
  },
  {
    name: 'Grandmaster Flash',
    email: 'grandmasterflash@example.com',
    status: 'expired',
  },
  {
    name: 'LL Cool J',
    email: 'llcoolj@example.com',
    status: 'pending',
  },
  {
    name: 'Rakim',
    email: 'rakim@example.com',
    status: 'expired',
  },
  {
    name: 'KRS-One',
    email: 'krsone@example.com',
    status: 'pending',
  },
  {
    name: 'Slick Rick',
    email: 'slickrick@example.com',
    status: 'expired',
  },
  {
    name: 'Nas',
    email: 'nas@example.com',
    status: 'pending',
  },
  {
    name: 'Big Daddy Kane',
    email: 'bigdaddykane@example.com',
    status: 'expired',
  },
  {
    name: 'Public Enemy',
    email: 'publicenemy@example.com',
    status: 'pending',
  },
  {
    name: 'Mobb Deep',
    email: 'mobbdeep@example.com',
    status: 'expired',
  },
];
