import { useEffect } from 'react';
import { useQueryParams, NumberParam } from 'use-query-params';
import { useDebouncedCallback } from 'use-debounce';
import { create } from 'zustand';
import { ViewState } from 'react-map-gl';
import { defaultMapViewState } from '../helper';

type State = {
  la?: number;
  ln?: number;
  z?: number;
};

type Action = {
  setMapViewStore: (viewState: State) => void;
};

const useMapViewStore = create<State & Action>((set) => ({
  la: undefined,
  ln: undefined,
  z: undefined,
  setMapViewStore: ({ la, ln, z }: State) => set(() => ({ la, ln, z })),
}));

export const useMapViewState = () => {
  const { la, ln, z, setMapViewStore } = useMapViewStore();
  const [query, setQuery] = useQueryParams<{
    la: typeof NumberParam;
    ln: typeof NumberParam;
    z: typeof NumberParam;
  }>();

  // state || url || default
  const longitude = ln || query.ln || defaultMapViewState.longitude;
  const latitude = la || query.la || defaultMapViewState.latitude;
  const zoom = z || query.z || defaultMapViewState.zoom;

  const setViewState = useDebouncedCallback(
    ({ longitude, latitude, zoom }: Partial<ViewState | any>) => {
      setQuery({ ln: longitude, la: latitude, z: zoom }); // updating query params
      setMapViewStore({ ln: longitude, la: latitude, z: zoom }); // updating zustand state
    },
    150,
    { leading: true, trailing: true }
  );

  useEffect(() => setViewState({ longitude, latitude, zoom }), []);

  return {
    viewState: { longitude, latitude, zoom } as Partial<ViewState>,
    setViewState,
  };
};
