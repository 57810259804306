import mixpanel from 'mixpanel-browser';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useUser } from '.';

const trackingEnabled =
  import.meta.env.NX_TRACKING_ENABLED === 'true' &&
  import.meta.env.NX_ENV !== 'undefined';

if (import.meta.env.NX_MIXPANEL_TOKEN && trackingEnabled) {
  mixpanel.init(import.meta.env.NX_MIXPANEL_TOKEN, {
    debug:
      import.meta.env.NX_ENV === 'dev' ||
      import.meta.env.NX_ENV === 'undefined',
    persistence: 'localStorage',
    api_host: 'https://api-eu.mixpanel.com',
  });
}

type EventProperties = Record<string, any>;

type TrackingContextType = {
  trackEvent: (eventName: string, properties?: EventProperties) => void;
};

const TrackingContext = createContext<TrackingContextType | null>(null);

export const useTracking = () => {
  const context = useContext(TrackingContext);
  const { data: user } = useUser();
  const defaultProperties = useMemo(() => {
    const p: EventProperties = {};
    if (user) {
      // p.org_id = user.business.auth0OrganizationId;
      p.org_internal_id = user.businessId;
      p.org_name = user.business?.name;
      p.user_language = user.locale;
      p.user_connection = user.auth0UserId.split('|')[0];
    }

    return p;
  }, [user]);

  const trackEvent = useCallback(
    (eventName: string, properties?: EventProperties) => {
      context?.trackEvent(eventName, { ...defaultProperties, ...properties });
    },
    [context, defaultProperties]
  );

  return { trackEvent };
};

let hasInitialized = false;

export const TrackingProvider = ({ children }: PropsWithChildren) => {
  const consentCookie = (window as any).Cookiebot?.consent;

  const trackEvent = (eventName: string, properties?: EventProperties) => {
    if (trackingEnabled) {
      mixpanel.track(eventName, properties);
    }
  };

  useEffect(() => {
    if (trackingEnabled && !hasInitialized) {
      if (!consentCookie?.statistics) {
        mixpanel.opt_out_tracking();
      } else {
        mixpanel.opt_in_tracking();
      }
      hasInitialized = true;
    }
  }, [consentCookie?.statistics]);

  return (
    <TrackingContext.Provider value={{ trackEvent }}>
      {children}
    </TrackingContext.Provider>
  );
};
