import { Chip as MantineChip, ChipProps } from '@mantine/core';
import { withRef } from '../../helpers/typescript';
import classes from './Chip.module.css';

type CustomChipProps = ChipProps & { customIcon: Element };

const _Chip = ({ customIcon, ...props }: CustomChipProps, ref: any) => {
  return <MantineChip {...props} ref={ref} classNames={classes} />;
};

export const Chip = withRef<
  HTMLButtonElement,
  CustomChipProps,
  typeof MantineChip
>({
  component: _Chip,
});

Chip['Group'] = MantineChip.Group;
