import { Modal, Text, Stack, Button, Group } from '@liveeo/component-library';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useBusiness } from '../../../hooks';
import classes from '../Admin.module.css';

type ClientModalProps = {
  businessId: string | undefined;
};

export const CreateClientModal = ({ businessId }: ClientModalProps) => {
  const { t } = useTranslation();
  const { data: business, createClient } = useBusiness(businessId);
  const navigate = useNavigate();
  const showModal = !business?.machineToMachineClientId;

  return (
    <Modal
      opened={showModal}
      onClose={() => navigate('/admin')}
      centered
      overlayProps={{
        backgroundOpacity: 0.55,
        blur: 3,
      }}
      title={<Text size="lg">{t<string>('createClient.title')}</Text>}
      //inline styles used because CSS module did not affect modal background color
      styles={{
        content: { backgroundColor: '#171617' },
        header: { backgroundColor: '#171617' },
      }}
    >
      <Stack>
        <Text>{t<string>('createClient.body.text1')}</Text>
        <Text>{t<string>('createClient.body.text2')}</Text>
        <Group justify="flex-end">
          <Button
            variant="outline"
            onClick={() => navigate('/admin')}
            className={classes['button']}
          >
            {t<string>('common.cancel')}
          </Button>

          <Button onClick={() => createClient({})}>
            {t<string>('createClient.confirm')}
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
