import React, { PropsWithChildren, useState } from 'react';
import { Body, Cell, Row } from './Body';
import { Header, HeaderCell, HeaderRow, HeaderTitle } from './Header';
import { ScrollArea } from '@mantine/core';
import { TableContext } from './TableContext';
import { SortButtons } from './SortButtons';
import { TableFooterPanel } from './TableFooter';
import { TableLoader } from './TableLoader';
import { Subrow } from './Subrow';
import { EmptyTable } from './EmptyTable';
import classes from './Table.module.css';

export type NamedComponent<P> = React.FunctionComponent<P> & {
  componentName?: string;
};

type Props = PropsWithChildren<{
  footerWidth?: string;
  height?: number | string;
}>;

export const Table = ({ children, height = 'auto' }: Props) => {
  const [scrolled, setScrolled] = useState(false);

  return (
    <TableContext.Provider value={{ scrolled }}>
      <ScrollArea
        style={{ height }}
        onScrollPositionChange={({ y }) => setScrolled(y !== 0)}
        pos="relative"
      >
        <table className={classes['table']}>{children}</table>
      </ScrollArea>
    </TableContext.Provider>
  );
};

Table.Header = Header;
Table.HeaderRow = HeaderRow;
Table.HeaderCell = HeaderCell;
Table.HeaderTitle = HeaderTitle;

Table.Body = Body;
Table.Row = Row;
Table.Cell = Cell;
Table.Subrow = Subrow;

Table.EmptyState = EmptyTable;
Table.Loader = TableLoader;
Table.SortButtons = SortButtons;
Table.FooterPanel = TableFooterPanel;
