import {
  Button,
  Center,
  Stack,
  Text,
  Title,
  Group,
} from '@liveeo/component-library';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const AccessDeniedPage = () => {
  const { t } = useTranslation();
  return (
    <Center>
      <Stack bg="dark.0" p="md" mt="10vh" w="500px">
        <Title my="xs" mb="sm" order={4} c="dark.9">
          {t('accessDenied.title')}
        </Title>
        <Text c="dark.9">{t('accessDenied.text')}</Text>
        <Group justify="flex-end">
          <Button to="/" component={Link}>
            {t('accessDenied.button')}
          </Button>
        </Group>
      </Stack>
    </Center>
  );
};
