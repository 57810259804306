import { z } from 'zod';

/** Defined in Auth0 -> UserManagement -> Roles */
export const schema = z.union([
  // The following roles are deprecated and should not be used:
  z.literal('BuyerOrganizationAdmin'),
  z.literal('ExampleUser'),
  z.literal('ExampleViewer'),
  // The following roles are the ones that should be used:
  z.literal('Supplier'),
  z.literal('Admin'),
  z.literal('Member'),
]);
export type Role = z.infer<typeof schema>;

export const hasRole = (allowedRoles: Role[], userRoles: Role[]) =>
  allowedRoles.some((role) => userRoles.includes(role));
