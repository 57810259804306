import { useTranslation } from 'react-i18next';
import { Text, Stack, Center, Title, Beta } from '@liveeo/component-library';

export const NoNotifications = () => {
  const { t } = useTranslation();
  return (
    <Center mt={100}>
      <Stack align="center" gap={5}>
        <Beta.Icon icon="bell" size="lg" />
        <Title order={4}>{t('notifications.empty.title')}</Title>
        <Text>{t('notifications.empty.text')}</Text>
      </Stack>
    </Center>
  );
};
