import { ReactNode } from 'react';
import { MantineProvider, MantineProviderProps } from '@mantine/core';
import { mantineTheme } from '../styles/theme';
import '@mantine/core/styles.css';

type Props = {
  children: ReactNode;
  theme?: MantineProviderProps['theme'];
  colorScheme?: 'dark' | 'light';
};

export const ComponentLibraryProvider = ({
  children,
  theme,
  colorScheme = 'light',
}: Props) => {
  return (
    <MantineProvider
      theme={{
        ...mantineTheme,
        ...theme,
      }}
      defaultColorScheme={colorScheme}
    >
      {children}
    </MantineProvider>
  );
};
