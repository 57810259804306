import { PropsWithChildren } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Commodities } from '../../shared/types';
import {
  Text,
  Stack,
  Modal,
  Title,
  Flex,
  Button,
  Select,
} from '@liveeo/component-library';
import classes from './SelectCommodity.module.css';

import { defaultTheme } from '../../theme/theme';
type Props = {
  setCommodity: (commodity: Commodities) => void;
  resetUploader: () => void;
};

export const SelectCommodity = ({ setCommodity, resetUploader }: Props) => {
  const { t } = useTranslation();
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: {
      commodity: '' as Commodities,
    },
    resolver: yupResolver(
      yup.object({
        commodity: yup.string().oneOf(Object.values(Commodities)).required(),
      })
    ),
  });

  const translatedCommodities = Object.entries(Commodities).map(
    ([label, value]) => ({
      value,
      label: t<string>(`common.commodities.${label}`),
    })
  );

  return (
    <Modal
      opened
      centered
      styles={{
        content: {
          color: defaultTheme.colors.white,
        },
      }}
      size={500}
      onClose={resetUploader}
      title={
        <div>
          <Title order={3}>
            {t('profile.onboarding.steps.commodity.title')}
          </Title>
        </div>
      }
    >
      <form onSubmit={handleSubmit(({ commodity }) => setCommodity(commodity))}>
        <Stack gap="xl">
          <Text>{t('profile.onboarding.steps.commodity.text')}</Text>
          <Controller
            name="commodity"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className={classes.root}
                label={t('suppliers.add.fields.commodities')}
                error={errors?.commodity?.message}
                data={translatedCommodities}
              />
            )}
          />
          <Flex<PropsWithChildren<any>> justify="flex-end">
            <Button type="submit" loading={isSubmitting}>
              {t('common.apply')}
            </Button>
          </Flex>
        </Stack>
      </form>
    </Modal>
  );
};
